import Rest from '@/services/Rest';

/**
 * @typedef {ReportService}
 */
export default class ReportService extends Rest {
  /**
   * @type {String}
   */
  static resource = '/report'
}
