import Rest from '@/services/Rest';

/**
 * @typedef {ProductService}
 */
export default class ProductService extends Rest {
  /**
   * @type {String}
   */
  static resource = '/product'
}
