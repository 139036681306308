import axios from 'axios';
import Cookies from 'js-cookie';

const standard = axios.create({
  baseURL: process.env.VUE_APP_API_HOST,
 // baseURL: 'https://dev-back.qsuite.com.br',
  timeout: 100000,
  transformResponse: [
    function (data) {
      return data;
    },
  ],
});
standard.interceptors

if (Cookies.get('access_token')) {
  standard.defaults.headers.common['Authorization'] = Cookies.get('access_token');
}

standard.defaults.headers.common['Access-Control-Allow-Origin'] = '*'

standard.interceptors.response.use(function (re) {
  return re;
}, function (error) {
  // return Promise.reject(error.toJSON())
  return Promise.reject(error)
})

export default standard;