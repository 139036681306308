<template>
    <div class="col-12">
            <div class="row">
                <div class="col-12 col-md-12 col-lg-11 px-lg-0">
                    <div class="row">
                        <div class="col-6 col-md-6 col-lg-3 pr-0 pt-3">
                            <div class="card h-100 border-right card-bg-dash">
                                <div class="card-body">
                                    <div class="d-flex d-lg-flex d-md-block align-items-center">
                                        <div>
                                            <h2 class="text-dark mb-1 w-100 text-truncate font-weight-medium">{{ active }}</h2>
                                            <h6 class="text-muted font-weight-normal mb-0 w-100 text-truncate">{{ title }} ativos</h6>
                                        </div>
                                        <div class="ml-auto mt-md-3 mt-lg-0">
                                            <span class="opacity-7 text-muted"><i class="far fa-thumbs-up font-25"></i></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-6 col-md-6 col-lg-3 pr-lg-0 pt-3">
                            <div class="card h-100 border-right card-bg-dash">
                                <div class="card-body">
                                    <div class="d-flex d-lg-flex d-md-block align-items-center">
                                        <div>
                                            <div class="d-inline-flex align-items-center">
                                                <h2 class="text-dark mb-1 font-weight-medium">{{ inactive }}</h2>
                                            </div>
                                            <h6 class="text-muted font-weight-normal mb-0 w-100 text-truncate">inativos</h6>
                                        </div>
                                        <div class="ml-auto mt-md-3 mt-lg-0">
                                            <span class="opacity-7 text-muted"><i class="far fa-hourglass font-25"></i></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-6 col-md-6 col-lg-3 pr-0 pt-3">
                            <div class="card h-100" style="background-color: #212121 !important;">
                                <div class="card-body">
                                    <div class="d-flex d-lg-flex d-md-block align-items-center">
                                        <div class="">
                                            <h2 class="text-white mb-1 font-weight-medium">{{ total }}</h2>
                                            <h6 class="font-weight-normal mb-0 w-100 text-white" style="color: #ffffff;">todos</h6>
                                        </div>
                                        <div class="ml-auto mt-md-3 mt-lg-0">
                                            <span class="text-muted"><i data-feather="globe" class="" style="color: #02b475;"></i></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-6 col-md-6 col-lg-3 pt-3">
                            <div class="card h-100 border-right card-bg-dash">
                                <div class="card-body">
                                    <div class="d-flex d-lg-flex d-md-block align-items-center">
                                        <div>
                                            <div class="d-inline-flex align-items-center">
                                                <h2 class="text-dark mb-1 font-weight-medium font-20">Exportar</h2>
                                            </div>
                                            <h6 class="text-muted font-weight-normal mb-0 w-100 text-truncate"><a href="javascript::void(0);" @click="$emit('export', 'pdf')">PDF</a> | <a href="javascript::void(0);" @click="$emit('export', 'excel')">EXCEL</a></h6>
                                        </div>
                                        <div class="ml-auto mt-md-3 mt-lg-0 d-none d-lg-block">
                                            <span class="opacity-7 text-muted"><i class="far fa-list-alt font-25"></i></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
</template>
<script>
export default {
    props:{
        active:{
            type: Number,
            default: 0
        },
        inactive:{
            type: Number,
            default: 0
        },
        total:{
            type: Number,
            default: 0
        },
        title:{
            type: String,
            default: ''
        }
    }
}
</script>