<template>
    <div class="row">
        <div class="col-12">
            <label>Tipologia</label>
            <div class="input-group">
                <div class="input-group-prepend">
                    <div class="input-group-text" id="btnGroupAddon2"><i class="fas fa-search"></i></div>
                </div>
                <input type="text" class="form-control" placeholder="Procurar" v-model="search">
            </div>
        </div>
        <div class="col-12">
            <div data-spy="scroll" data-offset="0" class="position-relative mt-2 scroll-1">
                <div class="col-12">
                    <b-form-checkbox-group
                        v-if="multiple"
                        v-model="selected"
                        :options="options">
                    </b-form-checkbox-group>

                    <b-form-radio-group
                        v-else
                        v-model="selected"
                        :options="options">
                    </b-form-radio-group>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import TipologyService from "@/services/resources/TipologyService";
const service = TipologyService.build();

export default {
    props:{
        value:{
            type: [Number, Array],
        },
        multiple:{
            type: Boolean,
            default: false
        },
        tipology_id:{
            type: Number
        },
        trigger_disabled:{
            type: Boolean,
            default: false
        }
    },
    data(){
        return{
            selected: [],
            search: '',
            optionsList: []
        }
    },
    computed:{
        options(){
            return this.optionsList.filter(elem => {
                return elem.html.toLowerCase().includes(this.search.toLowerCase());
            });
        }
    },
    watch:{
        value(newValue){
            this.selected = newValue;
        },
        selected(newValue){
            this.$emit('input', newValue);
        },
        tipology_id(){
            this.fetchTipologies()
        }
    },
    methods:{
        fetchTipologies(){

            this.optionsList = [];
            
            service
                .search()
                .then(resp => {
                    let vm = this;
                    setTimeout(function(){
                        resp.forEach(element => {
                            let code = element.code? '<b>' + element.code + '</b> - '  : '';
                            vm.optionsList.push({
                                value: element.id,
                                html: `${code} ${element.name}`,
                                disabled: (vm.tipologyid != null && vm.tipology_id != element.id) || (vm.trigger_disabled && element.trigger_selected && typeof vm.value.find(elem => elem == element.id) === 'undefined')
                            });
                        });
                    }, 1000);
                
                })
                .catch(err => {
                    console.log(err);
                })

       },
    },
    mounted(){
        this.fetchTipologies();
    }
}
</script>