import Api from '@/services/API';
import axios from '@/config/service/axios'
import Cookies from 'js-cookie';

/**
 * @typedef {Rest}
 */
export default class Rest extends Api {
  /**
   * @type {String}
   */
  static resource = ''

  /**
   * @type {String}
   */
  id = 'id'

  /**
   * @param {String} resource
   * @param {Object} options
   * @param {Object} http
   */
  constructor(resource, options = {}, http = null) {
    super(Rest.normalize(Rest.base, resource), options, http);
  }

  /**
   * @return {this}
   */
  static build() {
    return new this(this.resource);
  }

  /**
   * @param {Object} record
   * @returns {*|PromiseLike<T | never>|Promise<T | never>}
   */
  create(record) {
    let url = typeof record.url != 'undefined' ? record.url : '';
    return this.post(url, record);
  }

  /**
   * @param {String|Object} record
   * @returns {*|PromiseLike<T | never>|Promise<T | never>}
   */
  read(record) {
    return this.get(`/${this.getId(record)}`);
  }

  /**
   * @param {Object} record
   * @returns {*|PromiseLike<T | never>|Promise<T | never>}
   */
  update(record) {
    let url = '';
    if (typeof record['url'] != 'undefined') {
      url += record['url'];
    }

    return this.put(`${url}/${this.getId(record)}`, record);
  }

  /**
   * @param {Object} record
   * @returns {*|PromiseLike<T | never>|Promise<T | never>}
   */
  destroy(record) {
    return this.delete(`/${this.getId(record)}`);
  }

  /**
   * @param {Object} parameters
   * @returns {*|PromiseLike<T | never>|Promise<T | never>}
   */
  search(parameters = {}) {
    let url = '';
    if (typeof parameters['url'] != 'undefined') {
      url = parameters['url'];
      delete parameters['url'];
    }

    const queryString = Object.keys(parameters).map(key => {

      if (Array.isArray(parameters[key])) {
        return key + '[]' + '=' + parameters[key].join(`&${key}[]=`)
      } else {
        return key + '=' + parameters[key]
      }

    }).join('&');
    // apply your logic here
    if (Object.keys(parameters).length)
      return this.get(`${url}?${queryString}`);

    return this.get(url);
  }

  /**
   * @param {String|Object} record
   * @returns {String}
   */
  getId(record) {
    if (typeof record === 'object') {
      return record[this.id]
    }
    return String(record);
  }

  download(api, parameters = {}, id = null, name) {

    if (id) {
      api = api + `/${id}`;
    }

    return axios.get(api, {
      responseType: 'blob',
      params: parameters
    }).then(response => {

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', name);
      document.body.appendChild(link);
      link.click();

    });

  }
}