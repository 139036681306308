import Cookies from 'js-cookie';
import router from '@/router/index'
import store from '../../index'


export default {

    userSuccess: (state, resp) => {
        state.user = resp;
        Cookies.set('user', JSON.stringify(resp), {
            expires: 365
        });
    },
    redirectToDashboard: (state) => {
        router.push('/dashboard');
    },
    userLogout: (state) => {

        if (store.getters.isQsuiteAdmin) {
            router.push('/qs-admin');
        } else if (store.getters.isClient) {
            router.push('/');
        } else if (store.getters.isTechnical) {
            router.push('/tecnico');
        } else if (store.getters.isLiquidator) {
            router.push('/');
        } else if (store.getters.isBroker) {
            router.push('/dashboard');
        }
        else {
            router.push('/');
        }
        state.user = null;
        Cookies.remove('user');
    }

}