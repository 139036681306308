<template>
    <div class="row">
        <div class="col-12">
            <label>Apartamento</label>
            <div class="input-group">
                <div class="input-group-prepend">
                    <div class="input-group-text" id="btnGroupAddon2"><i class="fas fa-search"></i></div>
                </div>
                <input type="text" class="form-control" placeholder="Procurar" v-model="search">
            </div>
        </div>
        <div class="col-12">
            <div data-spy="scroll" style="height: 200px" data-offset="0" class="position-relative mt-2 scroll-1">
                <div class="col-12">
                    <div class="row" v-for="item in options" :key="item.name">
                        <div class="col-12">
                            <h4>{{ item.text }}</h4>
                        </div>
                        <div class="col-12">
                            <b-form-radio-group
                                stacked
                                v-model="selected"
                                @input="$emit('input', selected)"
                                :options="item.data">
                            </b-form-radio-group>
                        </div>
                    </div>
                    <div class="row justify-content-center pt-4" v-if="options.length == 0">
                        Sem apartamentos cadastrados.
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import EnterpriseService from "@/services/resources/EnterpriseService";
const serviceEnterprise = EnterpriseService.build();

export default {
    props:{
        value:{
            type: Number,
        },
        enterprise_id:{
            type: Number
        },
        apts: {
            type: Array,
        },
        edit:{
            type: Boolean,
            default: false
        },
        disabled:{
            type: Boolean,
            default: true
        },
        client:{
            type: Boolean,
            default: false
        }
    },
    data(){
        return{
            selected: [],
            search: '',
            optionsList: []
        }
    },
    computed:{
        options(){
            return this.optionsList.filter(elem => {
                return elem.data.filter(item => {
                    return String(item.text).includes(this.search);
                }).length > 0;
            });
        }
    },
    watch:{
        value(newValue){
            this.selected = newValue;
        },
        apts(newValue){
            this.apts = newValue;
        },
        enterprise_id(newValue){
            if(!newValue){
                this.optionsList = [];
            }else{
                this.selectEnterprise(this.enterprise_id);
            }
            
        }
    },
    methods:{
        generateGrounds(grounds){
            let total = 0;
            for(let i = 0; i < grounds.length; i++){
                total += grounds[i].apt_numbers * grounds[i].floors;
            }

            return total;
        },
        getFloors(floor, i){
            return `${floor}${String(i).padStart(2, '0')}`;
        },
        async selectEnterprise(id){
            if(!id){
                return;
            }
            this.optionsList = [];
            let enterprise = await serviceEnterprise.read({id: id}).then();
            enterprise.grounds.forEach(element => {
                let unities = [];
                element.unities.forEach(elem => {
                    elem.forEach(unity => {
                        if (unity.user !=null && unity.user.user && unity.user.user !== null) {
                            unities.push({   
                                text: unity.user && unity.user.user ? unity.number + " - "+ unity.user?.user?.first_name + " " + unity.user?.user?.last_name  : unity.number,
                                value: unity.id,
                                disabled: this.edit ? this.value.indexOf(unity.id) == -1 && unity.user_selected : (this.client ? typeof this.value.find(item => item == unity.id) == 'undefined' : this.disabled && unity.user_selected)
                            })   
                        } else {
                             unities.push({   
                                text: "Selecionar cliente para a unidade "+ unity.number,
                                value: unity.id,
                                disabled: this.edit ? this.value.indexOf(unity.id) == -1 && unity.user_selected : (this.client ? typeof this.value.find(item => item == unity.id) == 'undefined' : this.disabled && unity.user_selected)
                            })   
                        }
                    });
                });

                if(this.$store.getters.isClient) {

                    let unitiesTreated = [];

                    for(let i = 0; i < this.selected.length; i ++ ) {
                        for(let j = 0; j < unities.length; j ++) {
                            
                            if(+this.selected[i] === +unities[j].value) {
                                unitiesTreated.push(unities[j]);
                                break;
                            }
                        }  
                        if(unitiesTreated.length) break;
                    }
                   
                    this.optionsList.push({   
                        text: element.name,
                        data: unitiesTreated
                    })  


                } else {

                    this.optionsList.push({   
                        text: element.name,
                        data: unities
                    })   
                }
  
            });

            this.$emit('unities', [this.selected]);

        },
        async fetchEnterpriseConfig(type){
            let data = {
            type: type
            }
            return serviceEnterpriseConfig.search(data).then();
        },
    },
    async mounted(){
        this.selectEnterprise(this.enterprise_id);        
    }
}
</script>