<template>
<div>
<div class="preloader" v-show="loading">
    <div class="lds-ripple">
        <div class="lds-pos"></div>
        <div class="lds-pos"></div>
    </div>
</div>
<header class="topbar" data-navbarbg="skin6">
    <nav class="navbar top-navbar navbar-expand-md">
        <div class="navbar-header bder-navbar" data-logobg="skin6" style="width: 275px;">
            <a class="nav-toggler waves-effect waves-light d-block d-md-none" href="javascript:void(0)"><i class="ti-menu ti-close font-20"></i></a>
            <div class="navbar-brand">
                <b-link :to="{name: 'Dashboard'}" class="mx-auto">
                    <b class="logo-icon">
                        <img :src="logo" alt="homepage" class="dark-logo nav-logo" />
                    </b>
                    <!-- <span class="logo-text">
                        <img src="@/assets/img/logo-text.png" alt="homepage" class="dark-logo nav-logo" />
                    </span> -->
                </b-link>
            </div>
            <a
                class="topbartoggler d-block d-md-none waves-effect waves-light"
                href="javascript:void(0)"
                data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
            >
                <i class="icon-grid font-20"></i>
            </a>

        </div>
        <div class="navbar-collapse collapse" id="navbarSupportedContent">
            <ul class="navbar-nav w-50 float-left ml-3 pl-1">
                <li class="nav-item d-none">
                    <a class="nav-link" href="javascript:void(0)">
                        <div class="customize-input">
                            <select class="custom-select form-control bg-white custom-radius custom-shadow border-0">
                                <option selected>add</option>
                                <option value="1">imóvel</option>
                                <option value="1">cliente</option>
                                <option value="1">síndico</option>
                                <option value="1">técnico</option>
                            </select>
                        </div>
                    </a>
                </li>
                <li class="nav-item w-100 d-none d-md-block" style="position: relative;">
                    <a class="nav-link" href="javascript:void(0)">
                        <form>
                            <div class="customize-input">
                                <input class="form-control custom-radius border-0" type="search" placeholder="Procurar" aria-label="Search" style="border: 1px #f6f6f6 solid !important; height: 44px; background-color: #fff;" v-model="search" />
                                <i class="form-control-icon" data-feather="search"></i>
                            </div>
                        </form>
                    </a>

                    <div class="row" v-show="reports.length > 0 && show_search">
                        <div class="col-12 search-box">
                            <router-link @click.native="show_search = false" :to="{name: 'ReportEdit', params: {id: item.id}}" class="item" v-for="item in reports" :key="item.id">
                                {{ item.subject }}
                            </router-link>
                        </div>
                    </div>
                </li>
            </ul>
            <ul class="navbar-nav float-right ml-auto">
                <li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle pl-md-3 position-relative" href="javascript:void(0)" role="button" id="bell" data-bs-toggle="dropdown" data-bs-target="#bell">
                        <span><bell-icon size="1.5x" class="custom-class"></bell-icon></span>
                        <span class="badge badge-danger notify-no rounded-circle">5</span>
                    </a>
                    <div class="dropdown-menu dropdown-menu-right mailbox animated bounceInDown" style="border-radius: 15px; width: 300px;" id="bell">
                        <ul class="list-style-none">
                            <li>
                                <div class="col-12 text-center pt-3 pb-1"><strong>Notificações</strong></div>
                            </li>
                            <li>
                                <div class="message-center notifications position-relative">
                                    <div class="message-item w-100 text-center p-2">
                                        Sem notificações
                                    </div>
                                    <!-- <a href="javascript:void(0)" class="message-item d-flex align-items-center border-bottom px-3 py-2">
                                        <div class="btn btn-danger rounded-circle btn-circle"><i data-feather="bell" class="text-white"></i></div>
                                        <div class="w-100 d-inline-block v-middle pl-2">
                                            <h6 class="message-title mb-0 mt-1">Update de sistema</h6>
                                            <span>Até julho deste ano, usuários podem baixar o novo sistema gratuitamente.</span>
                                            <span class="text-nowrap d-block text-muted">12:42 | 07/01/2021</span>
                                        </div>
                                    </a>  -->
                                </div>
                            </li>
                            <!-- <li>
                                <a class="nav-link pt-2 text-center text-dark" href="javascript:void(0);">
                                    <span class="align-middle">Verificar todas as notificações</span>
                                    <i class="fa fa-angle-right align-middle"></i>
                                </a>
                            </li> -->
                        </ul>
                    </div>
                </li>
                <li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle" href="javascript:void(0)" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-bs-target="#settings2">
                         <settings-icon size="1.5x" class="custom-class"></settings-icon>
                    </a>
                    <div class="dropdown-menu dropdown-menu-right " aria-labelledby="navbarDropdown" style="border-radius: 15px;" id="settings2">
                        <!-- <a class="dropdown-item" href="#"><i data-feather="globe" class="feather-icon mr-2 ml-1" style="color:#9eabc0; height: 17px; width: 17px;"></i><span class="align-middle" style="font-size: 14px;"> Integrações</span></a>
                        <div class="dropdown-divider"></div>
                        <a class="dropdown-item" href="#"><i data-feather="edit-3" class="feather-icon mr-2 ml-1" style="color:#9eabc0; height: 17px; width: 17px;"></i><span class="align-middle" style="font-size: 14px;"> Documentação API</span></a>
                        <div class="dropdown-divider"></div> -->
                        <router-link class="dropdown-item" :to="{name: 'SettingsTheme'}"><i data-feather="settings" class="feather-icon mr-2 ml-1" style="color:#9eabc0; height: 17px; width: 17px;"></i><span class="align-middle" style="font-size: 14px;"> Configurações da conta</span></router-link>
                        <!-- <div class="dropdown-divider"></div>
                        <a class="dropdown-item" href="#"><i data-feather="message-square" class="feather-icon mr-2 ml-1" style="color:#9eabc0; height: 17px; width: 17px;"></i><span class="align-middle" style="font-size: 14px;"> Popup de notificações</span></a> -->
                        <router-link class="dropdown-item" :to="{name: 'Integration'}"><i class="fab fa-xbox font-20 feather-icon mr-2 ml-1"></i><span class="hide-menu">Integrações</span></router-link>
                        <div class="dropdown-divider"></div>
                    </div>
                </li>
                <li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle" href="javascript:void(0)" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <img src="https://www.seekpng.com/png/detail/41-410093_circled-user-icon-user-profile-icon-png.png" alt="user" class="rounded-circle" style="border: 2px #02b475 solid;" width="40" />
                        <span class="ml-2 d-none d-lg-inline-block d-none"><span>Olá,</span> <span>{{ user.first_name }}</span> <i data-feather="chevron-down" class="svg-icon"></i></span>
                    </a>
                    <div class="dropdown-menu dropdown-menu-right user-dd animated flipInY" style="border-radius: 0px;">
                        <div class="dropdown-item"><i data-feather="help-circle" class="svg-icon mr-2 ml-1" style="color:#9eabc0; height: 17px; width: 17px;"></i>Conta: <b class="ml-5 bg-light pl-3 pr-3">ID: QS0-{{id}}</b></div>
                        <a class="dropdown-item" href="javascript:void(0)"><i data-feather="help-circle" class="svg-icon mr-2 ml-1" style="color:#9eabc0; height: 17px; width: 17px;"></i> <span class="align-middle" style="font-size: 14px;"> Central de ajuda</span></a>
                        <div class="dropdown-divider my-0"></div>
                        <a class="dropdown-item" href="?app=modules/usuarios/painel/edit-usuario.php"><i data-feather="user" class="svg-icon mr-2 ml-1" style="color:#9eabc0; height: 17px; width: 17px;"></i> <span class="align-middle" style="font-size: 14px;">Minha conta</span></a>
                        <div class="dropdown-divider my-0"></div>
                        <a class="dropdown-item" href="javascript:void(0)" @click="logout"><i data-feather="power" class="svg-icon mr-2 ml-1" style="color:#9eabc0; height: 17px; width: 17px;"></i> <span class="align-middle" style="font-size: 14px;"> Sair</span></a>
                    </div>
                </li>
            </ul>
        </div>
    </nav>
</header>
</div>
</template>
<script>
import ReportService from "@/services/resources/ReportService";
import { SettingsIcon, BellIcon } from 'vue-feather-icons'

const service = ReportService.build();

export default {
    components:{
        SettingsIcon,
        BellIcon
    },
    data(){
        return{
            search: '',
            reports: [],
            show_search: false,
            logo: 'https://qsuite-buckets.s3.us-west-2.amazonaws.com/themes/eV8iIs7Jll.jpg'
        }
    },
    computed:{
        user(){
            return this.$store.getters.getProfile;
        },
        loading(){
            return this.$store.getters.loading;
        },
        id() {
            return this.$store.getters.getProfile.company_id
        }
    },
    watch:{
        search(newValue){
            this.show_search = true;
            this.fetchReports(newValue);
        }
    },
    methods:{
      logout(){
        this.$store.dispatch("logoutRequest").catch((err) => {});   
      },
      fetchReports(search){
        let data = {
            page: 1,
            per_page: 10,
            search: search
        }
      
        service
        .search(data)
          .then(resp => {
            this.reports = resp.data;
          })
          .catch(err => {
            console.log(err)
          })
      }
    },
    mounted() {
    //    if(this.user.company.themes.length >1) {
    //         this.user.company.themes.forEach((element) => {
    //             switch (element.key) {
    //             case "SETTINGS_LOGO":
    //                 this.logo = element.value ?? "https://qsuite-buckets.s3.us-west-2.amazonaws.com/themes/eV8iIs7Jll.jpg"
    //                 break;   
    //             } 
    //         })
    //     }
    }
}
</script>
<style lang="scss" scoped>
.search-box{
    position: absolute;
    background: #fff;
    padding: 10px;
    border: 1px #ddd solid;
    border-radius: 5px;
    margin-top: -10px;
    margin-left: 30px;

    .item{
        display: block;
        padding: 10px;
        &:hover{
            background: #eee;
        }
    }
}

</style>