<template>
    <div class="row">
        <div class="col-12">
            <label>Produtos</label>
            <div class="input-group">
                <div class="input-group-prepend">
                    <div class="input-group-text" id="btnGroupAddon2"><i class="fas fa-search"></i></div>
                </div>
                <input type="text" class="form-control" placeholder="Procurar" v-model="search">
            </div>
        </div>
        <div class="col-12">
            <div data-spy="scroll" data-offset="0" class="position-relative mt-2 scroll-1">
                <div class="col-12">
                    <b-form-checkbox-group
                        v-model="selected"
                        :options="options">
                    </b-form-checkbox-group>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import ProductService from "@/services/resources/ProductService";
const service = ProductService.build();

export default {
    props:{
        value:{
            type: Array
        }
    },
    data(){
        return{
            selected: [],
            search: '',
            optionsList: []
        }
    },
    computed:{
        options(){
            return this.optionsList.filter(elem => {
                return elem.html.toLowerCase().includes(this.search.toLowerCase());
            });
        }
    },
    watch:{
        value(newValue){
            this.selected = newValue;
        },
        selected(newValue){
            this.$emit('input', newValue);
        }
    },
    methods:{
        fetchProducts(){

            service.search()
                .then(resp => {
                    resp.forEach(element => {
                        this.optionsList.push({
                            html: `<b>${element.quantity}</b> - ${element.name}`,
                            value: element.id
                        });
                    });
                })
                .catch(err => {
                    console.log(err);
                });

        },
    },
    mounted(){
        this.fetchProducts();
    }
}
</script>