import store from '@/store/index'
import requestService from '../../services/RequestService'

function isAuthenticated(to, from, next) {
  if (store.getters.isAuthenticated) {
    if (!requestService.isFetching()) {
      requestService.startFetching();
    }
    
    return next()
  }

  return next('/')
}
const authentication = isAuthenticated

export default authentication