import UserLoggedService from '@/services/resources/UserLoggedService';

const service = UserLoggedService.build();

export default {
    userRequest: ({
        commit,
        dispatch
    }) => {
   
        return new Promise((resolve, reject) => {
            service.get('')
                .then((resp) => {
                    if(resp.company.id==4){
                        resp = { ...resp, unities: {}, broker:  resp.broker != null ? {creci: resp.broker.creci, landingpage_can: resp.broker.landingpage_can, image:resp.image} : {},  company: {themes: {}} }

                    }else {
                        resp = { ...resp, unities: {}, broker:  resp.broker != null ? {
                            creci: resp.broker.creci, 
                            landingpage_can: resp.broker.landingpage_can
                          } : {},  
                          company: {
                            themes: {}, 
                            permissions: resp.company.permissions, 
                            integrations: resp.company.integrations
                          }
                      }
                    }
                        
                    commit('userSuccess', resp);           
                    commit('redirectToDashboard');
                    resolve(resp)
                })
                .catch((err) => {
                    commit('userError');
                    reject(err)
                    // if resp is unauthorized, logout, to
                    // dispatch('authLogout')
                });
        })

    },
}