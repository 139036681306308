import store from '@/store/index'

function isAuthenticated(to, from, next) {
  if (store.getters.isAuthenticated) {
    return next('/dashboard')
  }

  return next()
}

const unauthentication = isAuthenticated

export default unauthentication