<template>
    <aside class="left-sidebar" data-sidebarbg="skin6" style="border-bottom-right-radius: 22px;">
    <div class="scroll-sidebar" data-sidebarbg="skin6">
        <nav class="sidebar-nav">
            <ul id="sidebarnav">
                <li class="sidebar-item">
                    <b-link :to="{name: 'Dashboard'}" class="sidebar-link sidebar-link" ><i class="fas fa-tachometer-alt font-20 feather-icon"></i><span class="hide-menu">Painel de controle</span></b-link>
                </li>
                <li class="list-divider"></li>
                <li class="sidebar-item">
                    <a class="sidebar-link has-arrow" href="javascript:void(0)" aria-expanded="false" data-bs-toggle="collapse" data-bs-target="#business"><i class="fas fa-building feather-icon font-20"></i><span class="hide-menu" >Empreendimentos</span></a>
                    <ul aria-expanded="false" class="collapse first-level base-level-line" id="business">
                        <li class="sidebar-item" v-if="checkPermission('IMOVEL')">
                            <b-link :to="{name: 'Enterprise'}" class="sidebar-link"><span class="hide-menu">Empreendimentos</span></b-link>
                        </li>
                      
                        <li class="sidebar-item" v-if="checkPermission('IMOVEL_CONFIGURACOES')">
                            <a class="has-arrow sidebar-link" href="javascript:void(0)" aria-expanded="false" data-bs-toggle="collapse" data-bs-target="#settings"><span class="hide-menu">Configurações</span></a>
                            <ul aria-expanded="false" class="collapse second-level base-level-line ml-0 pl-3" id="settings">
                                <li class="sidebar-item">
                                    <b-link :to="{name: 'EnterpriseConfigType'}" class="sidebar-link"><span class="hide-menu"> Tipo de imóvel</span></b-link>
                                </li>
                                <li class="sidebar-item">
                                     <b-link :to="{name: 'EnterpriseConfigDifferences'}" class="sidebar-link"><span class="hide-menu"> Diferenciais</span></b-link>
                                </li>
                                <li class="sidebar-item">
                                     <b-link :to="{name: 'EnterpriseConfigCond'}" class="sidebar-link"><span class="hide-menu"> Condomínio</span></b-link>
                                </li>
                                <li class="sidebar-item">
                                     <b-link :to="{name: 'EnterpriseConfigStatus'}" class="sidebar-link"><span class="hide-menu"> Status</span></b-link>
                                </li>                               
                                <li class="sidebar-item">
                                    <b-link :to="{name: 'EnterpriseConfigMetragem'}" class="sidebar-link"><span class="hide-menu">Metragem</span></b-link>
                                </li>
                                <li class="sidebar-item">
                                    <b-link :to="{name: 'EnterpriseConfigFinalidade'}" class="sidebar-link"><span class="hide-menu">Finalidade</span></b-link>
                                </li>
                                <li class="sidebar-item">
                                     <b-link :to="{name: 'EnterpriseConfigTipology'}" class="sidebar-link"><span class="hide-menu"> Tipologia</span></b-link>
                                </li>
                                 <li class="sidebar-item">
                                    <b-link :to="{name: 'EnterpriseConfigQuarto'}" class="sidebar-link"><span class="hide-menu">Quarto</span></b-link>
                                </li>
                                 <li class="sidebar-item">
                                    <b-link :to="{name: 'EnterpriseConfigSuite'}" class="sidebar-link"><span class="hide-menu">Suíte</span></b-link>
                                </li>
                                <li class="sidebar-item" v-if="checkPermission('IMOVEL_RELACIONAR')">
                                    <b-link :to="{name: 'EnterpriseRelation'}" class="sidebar-link"><span class="hide-menu">Relacionar tipologia</span></b-link>
                                </li>
                                <!-- <li class="sidebar-item">
                                    <a href="javascript:void(0)" class="sidebar-link link-travado"><span class="hide-menu"> Gerar XML</span></a>
                                </li> -->
                            </ul>
                        </li>
                    </ul>
                </li>
                <li class="sidebar-item">
                    <a class="sidebar-link has-arrow" href="javascript:void(0)" aria-expanded="false" data-bs-toggle="collapse" data-bs-target="#realstate"><i class="fas fa-building feather-icon font-20"></i><span class="hide-menu" >Imobiliárias</span></a>
                    <ul aria-expanded="false" class="collapse first-level base-level-line" id="realstate">
                        <li class="sidebar-item" v-if="checkPermission('IMOVEL')">
                            <b-link :to="{name: 'RealStateNew'}" class="sidebar-link"><span class="hide-menu">Novo cadastro</span></b-link>
                        </li>
                        <li class="sidebar-item" v-if="checkPermission('IMOVEL')">
                            <b-link :to="{name: 'RealState'}" class="sidebar-link"><span class="hide-menu"> Imobiliárias</span></b-link>
                        </li>
                    </ul>
                </li>
                <li class="sidebar-item">
                    <a class="sidebar-link has-arrow" href="javascript:void(0)" aria-expanded="false" data-bs-toggle="collapse" data-bs-target="#customer"><i class="fas fa-user-circle font-20 feather-icon"></i><span class="hide-menu">Portal do cliente</span></a>
                    <ul aria-expanded="false" class="collapse first-level base-level-line" id="customer">
                        <li class="sidebar-item" v-if="checkPermission('CLIENTE')">
                            <b-link :to="{name: 'Client'}" class="sidebar-link"><span class="hide-menu"> Clientes</span></b-link>
                        </li>
                        <li class="sidebar-item" v-if="checkPermission('CLIENTE')">
                            <b-link :to="{name: 'ClientAccess'}" class="sidebar-link"><span class="hide-menu"> Acessos</span></b-link>
                        </li>
                        <li class="sidebar-item" v-if="checkPermission('ASSISTENCIA_TECNICA')">
                            <a class="has-arrow sidebar-link" href="javascript:void(0)" aria-expanded="false" data-bs-toggle="collapse" data-bs-target="#at"><span class="hide-menu">Assistência técnica</span></a>
                            <ul aria-expanded="false" class="collapse second-level base-level-line ml-0 pl-3" id="at">
                                <li class="sidebar-item">
                                    <b-link :to="{name: 'Report'}"  class="sidebar-link"><span class="hide-menu"> Chamados</span></b-link>
                                </li>
                                <li class="sidebar-item">
                                    <b-link :to="{name: 'Technical'}" class="sidebar-link"><span class="hide-menu"> Técnicos</span></b-link>
                                </li>
                                <li class="sidebar-item">
                                    <b-link :to="{name: 'Liquidator'}" class="sidebar-link"><span class="hide-menu"> Síndicos</span></b-link>
                                </li>
                            </ul>
                        </li>
                        <li class="sidebar-item">
                            <a href="?app=suporte/ticket.php" class="sidebar-link"><span class="hide-menu"> Suporte</span></a>
                        </li>
                        <!--<li class="sidebar-item">
                            <a class="has-arrow sidebar-link" href="javascript:void(0)" aria-expanded="false"><span class="hide-menu">Financeiro</span></a>
                            <ul aria-expanded="false" class="collapse second-level base-level-line ml-0 pl-3">
                                <li class="sidebar-item">
                                    <a href="/financeiro" class="sidebar-link"><span class="hide-menu"> 2ª via de boleto</span></a>
                                </li>
                            </ul>
                        </li>-->
                        <li class="sidebar-item" v-if="checkPermission('KIT_ACABAMENTO')">
                            <a class="has-arrow sidebar-link" href="javascript:void(0)" aria-expanded="false"><span class="hide-menu">Personalização</span></a>
                            <ul aria-expanded="false" class="collapse second-level base-level-line ml-0 pl-3">
                                <li class="sidebar-item">
                                    <b-link :to="{name: 'Order'}" class="sidebar-link"><span class="hide-menu"> Pedidos</span></b-link>
                                </li>
                                <li class="sidebar-item">
                                    <b-link :to="{name: 'Trigger'}" class="sidebar-link"><span class="hide-menu"> Gatilhos</span></b-link>
                                </li>
                                <li class="sidebar-item">
                                    <b-link :to="{name: 'Kit'}"  class="sidebar-link"><span class="hide-menu"> Kits</span></b-link>
                                </li>
                                <li class="sidebar-item">
                                    <b-link :to="{name: 'Product'}"  class="sidebar-link"><span class="hide-menu"> Produtos</span></b-link>
                                </li>
                            </ul>
                        </li>
                        <!--<li class="sidebar-item">
                            <a class="has-arrow sidebar-link link-travado" href="javascript:void(0)" aria-expanded="false"><span class="hide-menu">Estágio da obra</span></a>
                            <ul aria-expanded="false" class="collapse second-level base-level-line  ml-0 pl-3 link-travado">
                                <li class="sidebar-item">
                                    <a href="javascript:void(0)" class="sidebar-link link-travado"><span class="hide-menu"> Novo estágio</span></a>
                                </li>
                                <li class="sidebar-item">
                                    <a href="javascript:void(0)" class="sidebar-link link-travado"><span class="hide-menu"> Obras</span></a>
                                </li>
                            </ul>
                        </li>
                        <li class="sidebar-item">
                            <a href="javascript:void(0)" class="sidebar-link link-travado"><span class="hide-menu"> Anexar documentos</span></a>
                        </li>
                        <li class="sidebar-item">
                            <a href="javascript:void(0)" class="sidebar-link link-travado"><span class="hide-menu"> Notificações</span></a>
                        </li> -->
                        <li class="sidebar-item" v-if="checkPermission('CLIENTE_CONFIGURACOES')">
                            <a class="has-arrow sidebar-link" href="javascript:void(0)" aria-expanded="false"><span class="hide-menu">Configurações</span></a>
                            <ul aria-expanded="false" class="collapse second-level base-level-line ml-0 pl-3">
                                <li class="sidebar-item">
                                    <b-link :to="{name: 'ConfigStatus'}" class="sidebar-link"><span class="hide-menu"><span class="font-12">AT</span> | Status</span></b-link>
                                </li>
                                <li class="sidebar-item">
                                    <b-link :to="{name: 'ConfigNotOk'}" class="sidebar-link"><span class="hide-menu"><span class="font-12">AT</span> | Não conformidades</span></b-link>
                                </li>
                                <li class="sidebar-item">
                                    <b-link :to="{name: 'ConfigPrecedents'}" class="sidebar-link"><span class="hide-menu"><span class="font-12">AT</span> | Procedentes</span></b-link>
                                </li>
                                <li class="sidebar-item">
                                    <b-link :to="{name: 'ConfigEmail'}" class="sidebar-link link-travado"><span class="hide-menu"><span class="font-12">AT</span> | E-mails</span></b-link>
                                </li>
                                <li class="sidebar-item">
                                    <b-link :to="{name: 'ConfigSms'}" class="sidebar-link link-travado"><span class="hide-menu"><span class="font-12">AT</span> | SMS</span></b-link>
                                </li>
                                <li class="sidebar-item">
                                    <b-link :to="{name: 'ConfigWelcome'}" class="sidebar-link"><span class="hide-menu"><span class="font-12">PE</span> | Bem vindo</span></b-link>
                                </li>
                                <li class="sidebar-item">
                                    <b-link :to="{name: 'ConfigCategory'}" class="sidebar-link"><span class="hide-menu"><span class="font-12">PE</span> | Categorias</span></b-link>
                                </li>
                            
                                <li class="sidebar-item">
                                    <b-link :to="{name: 'ConfigType'}" class="sidebar-link"><span class="hide-menu"><span class="font-12">PE</span> | Tipo de personalização</span></b-link>
                                </li>
                                <li class="sidebar-item">
                                    <b-link :to="{name: 'ConfigTypeItens'}" class="sidebar-link"><span class="hide-menu"><span class="font-12">PE</span> | Itens do tipo</span></b-link>
                                </li>
                                <!-- <li class="sidebar-item">
                                    <b-link :to="{name: 'ConfigNiche'}" class="sidebar-link"><span class="hide-menu"><span class="font-12">PE</span> | Nichos</span></b-link>
                                </li> -->
                                <li class="sidebar-item">
                                    <b-link :to="{name: 'ConfigGallery'}" class="sidebar-link"><span class="hide-menu"><span class="font-12">PE</span> | Galeria de fotos</span></b-link>
                                </li>
                                <li class="sidebar-item">
                                    <b-link :to="{name: 'ConfigSubdivision'}" class="sidebar-link"><span class="hide-menu"><span class="font-12">PE</span> | Subdivisões</span></b-link>
                                </li>
                                <li class="sidebar-item">
                                    <b-link :to="{name: 'ConfigCondition'}" class="sidebar-link"><span class="hide-menu">LGPD e Condições comerciais></span></b-link>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </li>

                <li class="sidebar-item">
                    <a class="sidebar-link has-arrow" href="javascript:void(0)" aria-expanded="false" data-bs-toggle="collapse" data-bs-target="#broker"><i class="fas fa-user-circle font-20 feather-icon"></i><span class="hide-menu">Portal do corretor</span></a>
                    <ul aria-expanded="false" class="collapse first-level base-level-line" id="broker">
                        <li class="sidebar-item" v-if="checkPermission('CLIENTE')">
                            <b-link :to="{name: 'Broker'}" class="sidebar-link"><span class="hide-menu"> Corretores</span></b-link>
                        </li>
                        <li class="sidebar-item" v-if="checkPermission('CLIENTE')">
                            <b-link :to="{name: 'EnterpriseFile'}" class="sidebar-link"><span class="hide-menu"> Enviar arquivos</span></b-link>
                           
                        </li>
                        <li class="sidebar-item" v-if="checkPermission('CLIENTE')">
                            <b-link :to="{name: 'EnterpriseFileList'}" class="sidebar-link"><span class="hide-menu"> Arquivos</span></b-link>
                           
                        </li>
                         <li class="sidebar-item" v-if="checkPermission('CLIENTE_CONFIGURACOES')">
                            <a class="has-arrow sidebar-link" href="javascript:void(0)" aria-expanded="false"><span class="hide-menu">Configurações</span></a>
                            <ul aria-expanded="false" class="collapse second-level base-level-line ml-0 pl-3">
                               
                                <li class="sidebar-item">
                                    <b-link :to="{name: 'ConfigAgentCondition'}" class="sidebar-link"><span class="hide-menu">LGPD e Condições</span></b-link>
                                </li>
                                  
                                <li class="sidebar-item">
                                    <b-link :to="{name: 'ConfigAgentLp'}" class="sidebar-link"><span class="hide-menu">Landing page</span></b-link>
                                </li>
                            </ul>
                        </li>

                        <!-- <li>
                            <a class="sidebar-link has-arrow" href="javascript:void(0)" aria-expanded="false" data-bs-toggle="collapse" data-bs-target="#send-to"><span class="hide-menu">Enviar para</span></a>
                            <ul aria-expanded="false" class="collapse first-level base-level-line" id="send-to">
                                <li class="sidebar-item" v-if="checkPermission('CLIENTE')">
                                    <b-link :to="{name: 'Broker'}" class="sidebar-link"><span class="hide-menu"> Corretores</span></b-link>
                                </li>
                                <li class="sidebar-item" v-if="checkPermission('CLIENTE')">
                                    <b-link :to="{name: 'EnterpriseFile'}" class="sidebar-link"><span class="hide-menu"> Empreendimentos</span></b-link>
                                </li>
                            </ul>
                        </li> -->

                    </ul>
                </li>

                <li class="sidebar-item" >
                    <a class="sidebar-link has-arrow" href="javascript:void(0)" aria-expanded="false" data-bs-toggle="collapse" data-bs-target="#review-at"><i class="fas fa-chart-pie font-20 feather-icon"></i><span class="hide-menu">Relatórios</span></a>
                    <ul aria-expanded="false" class="collapse first-level base-level-line" id="review-at">
                        <li class="sidebar-item" v-if="checkPermission('RELATORIOS_ASSISTENCIA_TECNICA')">
                            <a class="has-arrow sidebar-link" href="javascript:void(0)" aria-expanded="false"  data-bs-toggle="collapse" data-bs-target="#review"><span class="hide-menu">Assistência técnica</span></a>
                            <ul aria-expanded="false" class="collapse second-level base-level-line ml-0 pl-3" id="review">
                                <li class="sidebar-item">
                                    <router-link :to="{name: 'ReviewClient'}" class="sidebar-link"><span class="hide-menu"> Por cliente</span></router-link>
                                </li>
                                <li class="sidebar-item">
                                    <router-link :to="{name: 'ReviewLiquidator'}" class="sidebar-link"><span class="hide-menu"> Por síndico</span></router-link>
                                </li>
                                <li class="sidebar-item">
                                    <router-link :to="{name: 'ReviewTechnical'}" class="sidebar-link"><span class="hide-menu"> Por técnico</span></router-link>
                                </li>
                                <li class="sidebar-item">
                                    <router-link :to="{name: 'ReviewDate'}" class="sidebar-link"><span class="hide-menu"> Por período</span></router-link>
                                </li>
                                <li class="sidebar-item">
                                    <router-link :to="{name: 'ReviewStatus'}"  class="sidebar-link"><span class="hide-menu"> Por status</span></router-link>
                                </li>
                                <li class="sidebar-item">
                                    <router-link :to="{name: 'ReviewEnterprise'}" class="sidebar-link"><span class="hide-menu"> Por empreendimento</span></router-link>
                                </li>
                                <li class="sidebar-item">
                                    <router-link :to="{name: 'ReviewConformity'}"  class="sidebar-link"><span class="hide-menu"> Causa de não conformidade</span></router-link>
                                </li>
                                <li class="sidebar-item">
                                    <a href="javascript:void(0)" class="sidebar-link link-travado"><span class="hide-menu"> Demonstrativo mensal</span></a>
                                </li>
                                <li class="sidebar-item">
                                    <a href="javascript:void(0)" class="sidebar-link link-travado"><span class="hide-menu"> Nível de satisfação</span></a>
                                </li>
                            </ul>
                        </li>
                        <li class="sidebar-item">
                            <a class="has-arrow sidebar-link" href="javascript:void(0)" aria-expanded="false"  data-bs-toggle="collapse" data-bs-target="#review-dashboard-client"><span class="hide-menu">Portal do cliente</span></a>
                            <ul aria-expanded="false" class="collapse second-level base-level-line ml-0 pl-3" id="review-dashboard-client">
                                <li class="sidebar-item">
                                    <router-link :to="{name: 'LogsSystem'}" class="sidebar-link"><span class="hide-menu">Logs de acesso de cliente</span></router-link>
                                </li>
                            </ul>
                        </li>
                        <li class="sidebar-item">
                            <a class="has-arrow sidebar-link link-travado" href="javascript:void(0)" aria-expanded="false"><span class="hide-menu">Portal do cliente</span></a>
                            <ul aria-expanded="false" class="collapse second-level base-level-line ml-0 pl-3">
                                <li class="sidebar-item">
                                    <a href="javascript:void(0)" class="sidebar-link link-travado"><span class="hide-menu"> Nível de satisfação</span></a>
                                </li>
                            </ul>
                        </li>
                        <li class="sidebar-item">
                            <a class="has-arrow sidebar-link" href="javascript:void(0)" aria-expanded="false"><span class="hide-menu">Portal do corretor</span></a>
                            <ul aria-expanded="false" class="collapse second-level base-level-line ml-0 pl-3">
                                <li class="sidebar-item">
                                    <router-link :to="{name: 'AccessBrokers'}" class="sidebar-link"><span class="hide-menu">Logs de acesso de corretor</span></router-link>
                                </li>
                                <!-- <li class="sidebar-item">
                                    <a href="javascript:void(0)" class="sidebar-link link-travado"><span class="hide-menu"> item 1.3.1</span></a>
                                </li>
                                <li class="sidebar-item">
                                    <a href="javascript:void(0)" class="sidebar-link link-travado"><span class="hide-menu"> item 1.3.2</span></a>
                                </li>
                                <li class="sidebar-item">
                                    <a href="javascript:void(0)" class="sidebar-link link-travado"><span class="hide-menu"> item 1.3.3</span></a>
                                </li> -->
                                <li class="sidebar-item">
                                    <a href="javascript:void(0)" class="sidebar-link link-travado"><span class="hide-menu"> Pesquisa de satisfação</span></a>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </li>

                <li class="sidebar-item">
                    <b-link :to="{name: 'LeadsList'}" class="sidebar-link sidebar-link" ><i class="fas fa-comment-dots font-20 align-middle pr-2"></i><span class="hide-menu">Base de Leads</span></b-link>
                </li>
                <!-- <li class="sidebar-item">
                    <a class="sidebar-link has-arrow" href="javascript:void(0)" aria-expanded="false"><i class="far fa-id-card font-20 feather-icon"></i><span class="hide-menu">Portal do corretor</span></a>
                    <ul aria-expanded="false" class="collapse first-level base-level-line">
                        <li class="sidebar-item">
                            <a href="?app=portaldocorretor/corretores.php" class="sidebar-link"><span class="hide-menu"> Corretores</span></a>
                        </li>
                        <li class="sidebar-item">
                            <a class="has-arrow sidebar-link link-travado" href="javascript:void(0)" aria-expanded="false"><span class="hide-menu">Enviar arquivos</span></a>
                            <ul aria-expanded="false" class="collapse second-level base-level-line ml-0 pl-3">
                                <li class="sidebar-item">
                                    <a href="javascript:void(0)" class="sidebar-link link-travado"><span class="hide-menu"> Planilhas</span></a>
                                </li>
                                <li class="sidebar-item">
                                    <a href="javascript:void(0)" class="sidebar-link link-travado"><span class="hide-menu"> Documentos</span></a>
                                </li>
                                <li class="sidebar-item">
                                    <a href="javascript:void(0)" class="sidebar-link link-travado"><span class="hide-menu"> Imagens</span></a>
                                </li>
                            </ul>
                        </li>
                        <li class="sidebar-item">
                            <a class="has-arrow sidebar-link link-travado" href="javascript:void(0)" aria-expanded="false"><span class="hide-menu">Cursos e treinamentos</span></a>
                            <ul aria-expanded="false" class="collapse second-level base-level-line ml-0 pl-3">
                                <li class="sidebar-item">
                                    <a href="javascript:void(0)" class="sidebar-link link-travado"><span class="hide-menu"> Enviar vídeo</span></a>
                                </li>
                                <li class="sidebar-item">
                                    <a href="javascript:void(0)" class="sidebar-link link-travado"><span class="hide-menu"> Certificados</span></a>
                                </li>
                            </ul>
                        </li>
                        <li class="sidebar-item">
                            <a href="javascript:void(0)" class="sidebar-link link-travado"><span class="hide-menu"> Permissões de acesso</span></a>
                        </li>
                    </ul>
                </li> -->
                <!-- <li class="sidebar-item">
                    <a class="sidebar-link has-arrow" href="javascript:void(0)" aria-expanded="false"><i class="fas fa-chart-pie font-20 feather-icon"></i><span class="hide-menu">Relatórios</span></a>
                    <ul aria-expanded="false" class="collapse first-level base-level-line">
                        <li class="sidebar-item">
                            <a class="has-arrow sidebar-link" href="javascript:void(0)" aria-expanded="false"><span class="hide-menu">Assistência técnica</span></a>
                            <ul aria-expanded="false" class="collapse second-level base-level-line ml-0 pl-3">
                                <li class="sidebar-item">
                                    <a href="javascript:void(0)" class="sidebar-link"><span class="hide-menu"> item 1.3.1</span></a>
                                </li>
                                <li class="sidebar-item">
                                    <a href="javascript:void(0)" class="sidebar-link"><span class="hide-menu"> item 1.3.2</span></a>
                                </li>
                                <li class="sidebar-item">
                                    <a href="javascript:void(0)" class="sidebar-link"><span class="hide-menu"> item 1.3.3</span></a>
                                </li>
                            </ul>
                        </li>
                        <li class="sidebar-item">
                            <a class="has-arrow sidebar-link link-travado" href="javascript:void(0)" aria-expanded="false"><span class="hide-menu">Portal do cliente</span></a>
                            <ul aria-expanded="false" class="collapse second-level base-level-line ml-0 pl-3">
                                <li class="sidebar-item">
                                    <a href="javascript:void(0)" class="sidebar-link link-travado"><span class="hide-menu"> item 1.3.1</span></a>
                                </li>
                                <li class="sidebar-item">
                                    <a href="javascript:void(0)" class="sidebar-link link-travado"><span class="hide-menu"> item 1.3.2</span></a>
                                </li>
                                <li class="sidebar-item">
                                    <a href="javascript:void(0)" class="sidebar-link link-travado"><span class="hide-menu"> item 1.3.3</span></a>
                                </li>
                                <li class="sidebar-item">
                                    <a href="javascript:void(0)" class="sidebar-link link-travado"><span class="hide-menu"> Pesquisa de satisfação</span></a>
                                </li>
                            </ul>
                        </li>
                        <li class="sidebar-item">
                            <a class="has-arrow sidebar-link link-travado" href="javascript:void(0)" aria-expanded="false"><span class="hide-menu">Portal do corretor</span></a>
                            <ul aria-expanded="false" class="collapse second-level base-level-line ml-0 pl-3">
                                <li class="sidebar-item">
                                    <a href="javascript:void(0)" class="sidebar-link link-travado"><span class="hide-menu"> item 1.3.1</span></a>
                                </li>
                                <li class="sidebar-item">
                                    <a href="javascript:void(0)" class="sidebar-link link-travado"><span class="hide-menu"> item 1.3.2</span></a>
                                </li>
                                <li class="sidebar-item">
                                    <a href="javascript:void(0)" class="sidebar-link link-travado"><span class="hide-menu"> item 1.3.3</span></a>
                                </li>
                                <li class="sidebar-item">
                                    <a href="javascript:void(0)" class="sidebar-link link-travado"><span class="hide-menu"> Pesquisa de satisfação</span></a>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </li> -->
                <li class="sidebar-item">
                    <a class="sidebar-link has-arrow" href="javascript:void(0)" aria-expanded="false"><i class="fas fa-users font-20 feather-icon"></i><span class="hide-menu">Equipe</span></a>
                    <ul aria-expanded="false" class="collapse first-level base-level-line">
                        <li class="sidebar-item">
                            <router-link :to="{name: 'TimeUser'}" class="sidebar-link"><span class="hide-menu"> Usuários</span></router-link>
                        </li>
                        <li class="sidebar-item">
                            <a class="has-arrow sidebar-link" href="javascript:void(0)" aria-expanded="false"><span class="hide-menu"> Configurações</span></a>
                            <ul aria-expanded="false" class="collapse second-level base-level-line ml-0 pl-3">
                                <li class="sidebar-item">
                                    <router-link :to="{name: 'TimeTitle'}" class="sidebar-link"><span class="hide-menu"> Funções</span></router-link>
                                </li>
                                <li class="sidebar-item">
                                    <router-link :to="{name: 'TimePermission'}" class="sidebar-link"><span class="hide-menu"> Permissões de acesso</span></router-link>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </li>
                <li class="sidebar-item">
                    <b-link :to="{name: 'SuportList'}" class="sidebar-link sidebar-link" ><i class="fas fa-comment-dots font-20 align-middle pr-2"></i><span class="hide-menu">Suporte QS</span></b-link>
                </li>
                <li class="list-divider"></li>
                <li class="sidebar-item">
                    <a class="sidebar-link sidebar-link" href="javascript:void(0)" @click="logout" aria-expanded="false"><i class="fas fa-power-off font-20 feather-icon"></i><span class="hide-menu">Sair</span></a>
                </li>
                <li class="pt-4">
                    <img src="@/assets/img/ico-suporte.svg" class="img-fluid" title="Suporte">
                    <div style="width: max-content; margin: 0 auto; padding-top: 20px;">
                        <a class="" href="" style="border-radius: 11px; color: #ffffff; background-color: #212121; padding: 12px 40px; font-size: 14px; font-weight: 500;"><i class="fas fa-comment-dots font-20 align-middle pr-2"></i><span class="hide-menu">Suporte</span></a>
                    </div>
                    <br><br><br>
                </li>
            </ul>
        </nav>
    </div>
</aside>
</template>
<script>
import SideNav from "@/mixins/sideNav";

export default {
    mixins: [SideNav],
    methods:{
        logout(){
            this.$store.dispatch("logoutRequest").catch((err) => {});   
        },
        checkPermission(permission){
            if(typeof this.$store.getters.getProfile.permissions == 'undefined' || this.$store.getters.getProfile.permissions.length == 0){
                return true;
            }
            
            let permissions = this.$store.getters.getProfile.permissions.map(elem => JSON.parse(elem.permission.permissions));
            
            for(let i = 0; i < permissions.length; i++){
                if(permissions[i].indexOf(permission) != -1){
                    return true;
                }
            }

            return false;
        }
    },
    mounted(){
        this.mountedMenu();
    }
}
</script>