import Cookies from 'js-cookie';

let user = (Cookies.get('user')) ? JSON.parse(Cookies.get('user')) : {};
export default {
    getProfile: state => state.user ? state.user : user,
    isProfileLoaded: state => !!state.user.name,
    isKitPermission: (state) => {

        let permissions = state.user.company ? state.user.company.permissions : user.company.permissions;

        if (!permissions) {
            return false;
        }

        var isPermission = false;
        permissions.forEach(function (permission, index) {
            if (permission.permission.name === 'Kit Acabamento') {
                isPermission = true;
                return;
            }
        });
        return isPermission;
    },
    isAtPermission: (state) => {

        let permissions = state.user.company ? state.user.company.permissions : user.company.permissions;

        if (!permissions) {
            return false;
        }

        var isPermission = false;
        permissions.forEach(function (permission, index) {
            if (permission.permission.name === 'Assistência Técnica') {
                isPermission = true;
                return;
            }
        });
        return isPermission;
    },
    isClient: (state) => {

        let roles = state.user ? state.user.roles : user.roles;

        if (!roles) {
            return false;
        }

        var isClient = false;
        roles.forEach(function (role, index) {
            if (role.name === 'Client') {
                isClient = true;
                return;
            }
        });
        return isClient;
    },
    isAdmin: (state) => {

        let roles = state.user ? state.user.roles : user.roles;

        if (!roles) {
            return false;
        }

        var Admin = false;
        roles.forEach(function (role, index) {
            if (role.name === 'Admin') {
                Admin = true;
                return;
            }
        });
        return Admin;
    },
    isAgent: state => {
        let roles = state.user ? state.user.roles : user.roles;

        if (!roles) {
            return false;
        }

        var Agent = false;
        roles.forEach(function (role, index) {
            if (role.name === 'Agent') {
                Agent = true;
                return;
            }
        });
        return Agent;
    },
    isQsuiteAdmin: (state) => {

        let roles = state.user ? state.user.roles : user.roles;

        if (!roles) {
            return false;
        }

        var qsuiteAdmin = false;
        roles.forEach(function (role, index) {
            if (role.name === 'Qsuite Admin') {
                qsuiteAdmin = true;
                return;
            }
        });
        return qsuiteAdmin;
    },
    isTechnical: (state) => {

        let roles = state.user ? state.user.roles : user.roles;

        if (!roles) {
            return false;
        }

        var Technical = false;
        roles.forEach(function (role, index) {
            if (role.name === 'Technical') {
                Technical = true;
                return;
            }
        });
        return Technical;
    },
    isBroker: (state) => {

        let roles = state.user ? state.user.roles : user.roles;

        if (!roles) {
            return false;
        }

        var Technical = false;
        roles.forEach(function (role, index) {
            if (role.name === 'Broker') {
                Technical = true;
                return;
            }
        });
        return Technical;
    },
    isLiquidator: (state) => {

        let roles = state.user ? state.user.roles : user.roles;

        if (!roles) {
            return false;
        }

        var Liquidator = false;
        roles.forEach(function (role, index) {
            if (role.name === 'Liquidator') {
                Liquidator = true;
                return;
            }
        });
        return Liquidator;
    }
}
