<template>
<div style="padding-top: 80px">
<div class="preloader" v-show="loading">
    <div class="lds-ripple">
        <div class="lds-pos"></div>
        <div class="lds-pos"></div>
    </div>
</div>
<div class="" style="background-color: #fff; padding-top: 0px; padding-bottom: 0px; position: fixed; top: 0; width: 100%; z-index: 999;">
    <div class="w-100" style="height: 6px; background-color: var(--color)"></div>
<header class="topbar col-12 p-dash-cliente-master" data-navbarbg="skin6">
    <nav class="navbar top-navbar navbar-expand-md">
        <div class="navbar-header col p-0 order-1" data-logobg="skin6">
            <div class="col mr-auto pr-0">
                <b-link :to="{name: 'Dashboard'}" class="mx-auto">
                    <img :src="logo" alt="Qsuite" style="max-height: 48px;">
                </b-link>
            </div>
            <div class="col mr-auto pl-0 d-none d-lg-block">
                <a
                    class="topbartoggler d-block d-md-none waves-effect waves-light pl-0"
                    href="javascript:void(0)"
                    data-toggle="collapse"
                    data-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                >
                    <img src="~@/assets/img/icons/loupe.png" alt="Qsuite" height="25">
                </a>
                
            </div>
        </div>
        <!-- <div class="navbar-collapse collapse px-0 order-2 order-md-1 order-lg-1" id="navbarSupportedContent">
            <ul class="navbar-nav float-right ml-auto w-100">
                <li class="nav-item w-100 py-sm-0 py-2">
                    <form>
                        <div class="customize-input px-3 w-100">
                            <input class="form-control custom-radius border-0" type="search" placeholder="Busque por boleto, imóveis, comprovantes..." aria-label="Search" style="border: 1px #f6f6f6 solid !important; height: 44px; background-color: #f5f5f5;" />
                            <i class="form-control-icon" data-feather="search" style="top: 11px; right: 29px;"></i>
                        </div>
                    </form>
                </li>
            </ul>
        </div> -->
        <div class="order-1 order-md-2 order-lg-2">
            <ul class="navbar-nav float-right d-none">
                <li class="nav-item">
                    <div class="customize-input">
                        <select class="custom-select form-control bg-white custom-radius custom-shadow border-0">
                            <option selected>add</option>
                            <option value="1">imóvel</option>
                            <option value="1">cliente</option>
                            <option value="1">síndico</option>
                            <option value="1">técnico</option>
                        </select>
                    </div>
                </li>
            </ul>
            <ul class="navbar-nav float-right ml-auto">
                <!-- <li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle pl-md-3 position-relative" href="javascript:void(0)" id="bell" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <span><i data-feather="bell" class="svg-icon font-20"></i></span>
                        <span class="badge badge-danger notify-no rounded-circle" style="background-color: #ff9300;">5</span>
                    </a>
                    <div class="dropdown-menu dropdown-menu-right mailbox animated bounceInDown" style="border-radius: 15px;">
                        <ul class="list-style-none">
                            <li>
                                <div class="col-12 text-center pt-3 pb-1"><strong>Notificações</strong></div>
                            </li>
                            <li>
                                <div class="message-center notifications position-relative">
                                    <a href="javascript:void(0)" class="message-item d-flex align-items-center border-bottom px-3 py-2">
                                        <div class="btn btn-danger rounded-circle btn-circle"><i data-feather="bell" class="text-white"></i></div>
                                        <div class="w-100 d-inline-block v-middle pl-2">
                                            <h6 class="message-title mb-0 mt-1">Update de sistema</h6>
                                            <span>Até julho deste ano, usuários podem baixar o novo sistema gratuitamente.</span>
                                            <span class="text-nowrap d-block text-muted">12:42 | 07/01/2021</span>
                                        </div>
                                    </a>
                                    <a href="javascript:void(0)" class="message-item d-flex align-items-center border-bottom px-3 py-2">
                                        <div class="btn btn-danger rounded-circle btn-circle"><i data-feather="bell" class="text-white"></i></div>
                                        <div class="w-100 d-inline-block v-middle pl-2">
                                            <h6 class="message-title mb-0 mt-1">Update de sistema</h6>
                                            <span>Até julho deste ano, usuários podem baixar o novo sistema gratuitamente.</span>
                                            <span class="text-nowrap d-block text-muted">12:42 | 07/01/2021</span>
                                        </div>
                                    </a>
                                    <a href="javascript:void(0)" class="message-item d-flex align-items-center border-bottom px-3 py-2">
                                        <div class="btn btn-danger rounded-circle btn-circle"><i data-feather="bell" class="text-white"></i></div>
                                        <div class="w-100 d-inline-block v-middle pl-2">
                                            <h6 class="message-title mb-0 mt-1">Update de sistema</h6>
                                            <span>Até julho deste ano, usuários podem baixar o novo sistema gratuitamente.</span>
                                            <span class="text-nowrap d-block text-muted">12:42 | 07/01/2021</span>
                                        </div>
                                    </a>
                                    <a href="javascript:void(0)" class="message-item d-flex align-items-center border-bottom px-3 py-2">
                                        <div class="btn btn-danger rounded-circle btn-circle"><i data-feather="bell" class="text-white"></i></div>
                                        <div class="w-100 d-inline-block v-middle pl-2">
                                            <h6 class="message-title mb-0 mt-1">Update de sistema</h6>
                                            <span>Até julho deste ano, usuários podem baixar o novo sistema gratuitamente.</span>
                                            <span class="text-nowrap d-block text-muted">12:42 | 07/01/2021</span>
                                        </div>
                                    </a>
                                    
                                </div>
                            </li>
                            <li>
                                <a class="nav-link pt-2 text-center text-dark" href="javascript:void(0);">
                                    <span class="align-middle">Verificar todas as notificações</span>
                                    <i class="fa fa-angle-right align-middle"></i>
                                </a>
                            </li>
                        </ul>
                    </div>
                </li> -->
                <li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i data-feather="settings" class="svg-icon font-20"></i>
                    </a>
                    <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdown" style="border-radius: 15px;">
                        <a class="dropdown-item" href="?app=meus-imoveis.php"><i data-feather="globe" class="far fa-building mr-2 ml-1" style="color:#9eabc0; height: 17px; width: 17px;"></i><span class="align-middle" style="font-size: 14px;"> Meus imóveis</span></a>
                        <div class="dropdown-divider"></div>
                        <a class="dropdown-item" href="?app=alterar-senha.php"><i data-feather="edit-3" class="feather-icon mr-2 ml-1" style="color:#9eabc0; height: 17px; width: 17px;"></i><span class="align-middle" style="font-size: 14px;"> Alterar senha</span></a>
                        <!--
                        <div class="dropdown-divider"></div>
                        <a class="dropdown-item" href="#"><i data-feather="settings" class="feather-icon mr-2 ml-1" style="color:#9eabc0; height: 17px; width: 17px;"></i><span class="align-middle" style="font-size: 14px;"> Configurações da conta</a>
                        -->
                    </div> 
                </li>
                <li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle" href="javascript:void(0)" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <img src="https://www.seekpng.com/png/detail/41-410093_circled-user-icon-user-profile-icon-png.png" alt="user" class="rounded-circle" width="50" style="border: 2px var(--color) solid;" />
                        <span class="ml-2 d-none d-lg-inline-block d-none"><span style="font-size:11px; color:#212121;">{{ user.first_name }}</span> <i data-feather="chevron-down" class="svg-icon"></i></span>
                    </a>
                    <div class="dropdown-menu dropdown-menu-right user-dd animated flipInY" style="border-radius: 15px;">
                        <router-link class="dropdown-item" :to="{name: 'Dashboard'}"><i data-feather="grid" class="svg-icon mr-2 ml-1" style="color:#9eabc0; height: 17px; width: 17px;"></i> <span class="align-middle" style="font-size: 14px;"> Dashboard</span></router-link>
                        <router-link class="dropdown-item" :to="{name: 'Profile'}"><i data-feather="user" class="svg-icon mr-2 ml-1" style="color:#9eabc0; height: 17px; width: 17px;"></i> <span class="align-middle" style="font-size: 14px;"> Perfil</span></router-link>
                        <!-- <a class="dropdown-item" href="javascript:void(0)"><i data-feather="help-circle" class="svg-icon mr-2 ml-1" style="color:#9eabc0; height: 17px; width: 17px;"></i> <span class="align-middle" style="font-size: 14px;"> Central de ajuda</span></a> -->
                        <div class="dropdown-divider"></div>
                        <a class="dropdown-item" href="javascript:void(0);" @click="logout"><i data-feather="power" class="svg-icon mr-2 ml-1" style="color:#9eabc0; height: 17px; width: 17px;"></i> <span class="align-middle" style="font-size: 14px;"> Sair</span></a>
                    </div>
                </li>
            </ul>
        </div>
    </nav>
</header>
</div>
</div>
</template>

<script>
export default {
    props:{
        logo:{
            type: String
        },
        color:{
            type: String
        },
    },
    data(){
        return{
            loading: true,
            background_color: null
        }
    },
    methods:{
      logout(){
        this.$store.dispatch("logoutRequest").catch((err) => {});   
      },
    },
    computed:{
        user(){
            return this.$store.getters.getProfile;
        }
    },
    mounted(){
        setTimeout(() => {
            this.loading = false;         
        }, 1000*3);
        console.log(this.logoPanel);
    }
}
</script>