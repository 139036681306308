import Rest from '@/services/Rest';

/**
 * @typedef {TipologyService}
 */
export default class TipologyService extends Rest {
  /**
   * @type {String}
   */
  static resource = '/tipology'
}
