import Rest from '@/services/Rest';

/**
 * @typedef {EnterpriseService}
 */
export default class EnterpriseService extends Rest {
  /**
   * @type {String}
   */
  static resource = '/enterprise'
}
