import Cookies from 'js-cookie';


export default {

    authSuccess: (state, access_token) => {
        state.access_token = access_token;
    },
    authError: (state, err) => {
        /* let errors=err.errors?err.errors:{};
         if(err.error=="invalid_credentials"){
             errors.invalid_credentials=['The user credentials were incorrect.'];
         }

         state.status = 'error';
         state.hasLoadedOnce = true;
         state.errors.record(errors);*/
    },
    authLogout: (state) => {
        state.access_token = '';
        Cookies.remove('access_token');
    },
    setLoading: (state, loading) => {
        state.loading = loading;
    }
}