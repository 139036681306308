<template>
<aside class="left-sidebar" data-sidebarbg="skin6" style="border-bottom-right-radius: 22px;">
    <div class="scroll-sidebar" data-sidebarbg="skin6">
        <nav class="sidebar-nav">
            <ul id="sidebarnav">
                <li class="sidebar-item">
                    <a class="sidebar-link sidebar-link link-travado" href="#" aria-expanded="false"><i class="fas fa-tachometer-alt font-20 feather-icon"></i><span class="hide-menu">Painel de controle</span></a>
                </li>
                <li class="list-divider"></li>
                <li class="sidebar-item">
                    <a class="sidebar-link has-arrow" href="javascript:void(0)" aria-expanded="false" data-bs-toggle="collapse" data-bs-target="#accounts"><i class="far fa-user-circle feather-icon font-20" ></i><span class="hide-menu">Contas</span></a>
                    <ul aria-expanded="false" class="collapse first-level base-level-line" id="accounts">
                        <li class="sidebar-item">
                            <router-link :to="{name: 'Client'}" class="sidebar-link">
                                <span class="hide-menu"> Clientes</span>
                            </router-link>
                        </li>
                        <li class="sidebar-item">
                            <a class="has-arrow sidebar-link" href="javascript:void(0)" aria-expanded="false" data-bs-toggle="collapse" data-bs-target="#accounts-settings"><span class="hide-menu">Configurações</span></a>
                            <ul aria-expanded="false" class="collapse second-level base-level-line ml-0 pl-3" id="accounts-settings">
                                <li class="sidebar-item">
                                    <router-link :to="{name: 'Permission'}" class="sidebar-link"><span class="hide-menu"> Permissões</span></router-link>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </li>
             
                <li class="list-divider"></li>
                
                <li class="sidebar-item">
                    <b-link :to="{name: 'SuportList'}" class="sidebar-link sidebar-link" ><i class="fab fa-ioxhost font-20 feather-icon"></i><span class="hide-menu"> Suporte</span></b-link>
                </li>
                <li class="list-divider"></li>
                <li class="sidebar-item">
                    <a class="sidebar-link sidebar-link link-travado" href="#" aria-expanded="false"><i class="fab fa-windows font-20 feather-icon"></i><span class="hide-menu">Popup de notificações</span></a>
                </li>
                <li class="list-divider"></li>

                <li class="sidebar-item">
                    <a class="sidebar-link sidebar-link link-travado" href="#" aria-expanded="false"><i class="fab fa-xbox font-20 feather-icon"></i><span class="hide-menu">Integrações</span></a>
                </li>
                <li class="list-divider"></li>
                <li class="sidebar-item">
                    <a class="sidebar-link sidebar-link link-travado" href="#" aria-expanded="false"><i class="fab fa-codepen font-20 feather-icon"></i><span class="hide-menu">Documentação API</span></a>
                </li>
                <li class="list-divider"></li>
                <li class="sidebar-item">
                    <a class="sidebar-link has-arrow" href="javascript:void(0)" aria-expanded="false" data-bs-toggle="collapse" data-bs-target="#settings"><i class="fas fa-cogs font-20"></i><span class="hide-menu">Configurações da conta</span></a>
                    <ul aria-expanded="false" class="collapse first-level base-level-line" id="settings">
                        <li class="sidebar-item d-none">
                            <a href="?app=imoveis/imoveis.php" class="sidebar-link"><span class="hide-menu"> Conta</span></a>
                        </li>
                        <li class="sidebar-item">
                            <a class="has-arrow sidebar-link" href="javascript:void(0)" aria-expanded="false" data-bs-toggle="collapse" data-bs-target="#settings-access"><span class="hide-menu">Acessos</span></a>
                            <ul aria-expanded="false" class="collapse second-level base-level-line ml-0 pl-3" id="settings-access">
                                <li class="sidebar-item">
                                    <router-link :to="{name: 'User'}" class="sidebar-link"><span class="hide-menu"> Usuários</span></router-link>
                                </li>
                                <li class="sidebar-item">
                                    <router-link :to="{name: 'UserNew'}" class="sidebar-link"><span class="hide-menu"> Novo usuário</span></router-link>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </li>
                <li class="list-divider"></li>
                <li class="sidebar-item">
                    <a class="sidebar-link sidebar-link link-travado" href="#" aria-expanded="false"><i class="fab fa-nutritionix font-20 feather-icon"></i><span class="hide-menu"> Central de ajuda</span></a>
                </li>
                <li class="list-divider"></li>
                <li class="sidebar-item">
                    <a class="sidebar-link sidebar-link" href="javascript:void(0);" @click="logout" aria-expanded="false"><i class="fas fa-power-off font-19 feather-icon"></i><span class="hide-menu">Sair</span></a>
                </li>
                <!--
                <li class="sidebar-item">
                    <a class="sidebar-link has-arrow" href="javascript:void(0)" aria-expanded="false"><i class="fas fa-building feather-icon font-20"></i><span class="hide-menu">Empreendimentos</span></a>
                    <ul aria-expanded="false" class="collapse first-level base-level-line">
                        <li class="sidebar-item">
                            <a href="?app=imoveis/imoveis.php" class="sidebar-link"><span class="hide-menu"> Imóveis</span></a>
                        </li>
                        <li class="sidebar-item">
                            <a class="has-arrow sidebar-link" href="javascript:void(0)" aria-expanded="false"><span class="hide-menu">Configurações</span></a>
                            <ul aria-expanded="false" class="collapse second-level base-level-line ml-0 pl-3">
                                <li class="sidebar-item">
                                    <a href="?app=imoveis/config-tipo-de-imovel.php" class="sidebar-link"><span class="hide-menu"> Tipo de imóvel</span></a>
                                </li>
                                <li class="sidebar-item">
                                    <a href="?app=imoveis/config-diferenciais.php" class="sidebar-link"><span class="hide-menu"> Diferenciais</span></a>
                                </li>
                                <li class="sidebar-item">
                                    <a href="?app=imoveis/config-condominio.php" class="sidebar-link"><span class="hide-menu"> Condomínio</span></a>
                                </li>
                                <li class="sidebar-item">
                                    <a href="?app=imoveis/config-status.php" class="sidebar-link"><span class="hide-menu"> Status</span></a>
                                </li>
                                <li class="sidebar-item">
                                    <a href="javascript:void(0)" class="sidebar-link link-travado"><span class="hide-menu"> Gerar XML</span></a>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </li>
                <li class="list-divider"></li>
                <li class="sidebar-item">
                    <a class="sidebar-link has-arrow" href="javascript:void(0)" aria-expanded="false"><i class="fas fa-user-circle font-20 feather-icon"></i><span class="hide-menu">Portal do cliente</span></a>
                    <ul aria-expanded="false" class="collapse first-level base-level-line">
                        <li class="sidebar-item">
                            <a href="?app=portaldocliente/clientes.php" class="sidebar-link"><span class="hide-menu"> Clientes</span></a>
                        </li>
                        <li class="sidebar-item">
                            <a class="has-arrow sidebar-link" href="javascript:void(0)" aria-expanded="false"><span class="hide-menu">Assistência técnica</span></a>
                            <ul aria-expanded="false" class="collapse second-level base-level-line ml-0 pl-3">
                                <li class="sidebar-item">
                                    <a href="?app=assistenciatecnica/chamados.php" class="sidebar-link"><span class="hide-menu"> Chamados</span></a>
                                </li>
                                <li class="sidebar-item">
                                    <a href="?app=assistenciatecnica/tecnicos.php" class="sidebar-link"><span class="hide-menu"> Técnicos</span></a>
                                </li>
                                <li class="sidebar-item">
                                    <a href="?app=assistenciatecnica/sindicos.php" class="sidebar-link"><span class="hide-menu"> Síndicos</span></a>
                                </li>
                            </ul>
                        </li>
                        <li class="sidebar-item">
                            <a href="?app=suporte/ticket.php" class="sidebar-link"><span class="hide-menu"> Suporte</span></a>
                        </li>
                        <li class="sidebar-item">
                            <a class="has-arrow sidebar-link link-travado" href="javascript:void(0)" aria-expanded="false"><span class="hide-menu">Financeiro</span></a>
                            <ul aria-expanded="false" class="collapse second-level base-level-line ml-0 pl-3">
                                <li class="sidebar-item">
                                    <a href="javascript:void(0)" class="sidebar-link link-travado"><span class="hide-menu"> 2ª via de boleto</span></a>
                                </li>
                            </ul>
                        </li>
                        <li class="sidebar-item">
                            <a class="has-arrow sidebar-link link-travado" href="javascript:void(0)" aria-expanded="false"><span class="hide-menu">Kit acabamentos</span></a>
                            <ul aria-expanded="false" class="collapse second-level base-level-line ml-0 pl-3">
                                <li class="sidebar-item">
                                    <a href="javascript:void(0)" class="sidebar-link link-travado"><span class="hide-menu"> Novo kit</span></a>
                                </li>
                                <li class="sidebar-item">
                                    <a href="javascript:void(0)" class="sidebar-link link-travado"><span class="hide-menu"> Pedidos</span></a>
                                </li>
                            </ul>
                        </li>
                        <li class="sidebar-item">
                            <a class="has-arrow sidebar-link link-travado" href="javascript:void(0)" aria-expanded="false"><span class="hide-menu">Estágio da obra</span></a>
                            <ul aria-expanded="false" class="collapse second-level base-level-line  ml-0 pl-3 link-travado">
                                <li class="sidebar-item">
                                    <a href="javascript:void(0)" class="sidebar-link link-travado"><span class="hide-menu"> Novo estágio</span></a>
                                </li>
                                <li class="sidebar-item">
                                    <a href="javascript:void(0)" class="sidebar-link link-travado"><span class="hide-menu"> Obras</span></a>
                                </li>
                            </ul>
                        </li>
                        <li class="sidebar-item">
                            <a href="javascript:void(0)" class="sidebar-link link-travado"><span class="hide-menu"> Anexar documentos</span></a>
                        </li>
                        <li class="sidebar-item">
                            <a href="javascript:void(0)" class="sidebar-link link-travado"><span class="hide-menu"> Notificações</span></a>
                        </li>
                        <li class="sidebar-item">
                            <a class="has-arrow sidebar-link" href="javascript:void(0)" aria-expanded="false"><span class="hide-menu">Configurações</span></a>
                            <ul aria-expanded="false" class="collapse second-level base-level-line ml-0 pl-3">
                                <li class="sidebar-item">
                                    <a href="?app=assistenciatecnica/config-status.php" class="sidebar-link"><span class="hide-menu"><span class="font-12">AT</span> | Status</span></a>
                                </li>
                                <li class="sidebar-item">
                                    <a href="?app=assistenciatecnica/config-naoconformidades.php" class="sidebar-link"><span class="hide-menu"><span class="font-12">AT</span> | Não conformidades</span></a>
                                </li>
                                <li class="sidebar-item">
                                    <a href="?app=assistenciatecnica/config-procedentes.php" class="sidebar-link"><span class="hide-menu"><span class="font-12">AT</span> | Procedentes</span></a>
                                </li>
                                <li class="sidebar-item">
                                    <a href="?app=assistenciatecnica/config-e-mails.php" class="sidebar-link"><span class="hide-menu"><span class="font-12">AT</span> | E-mails</span></a>
                                </li>
                                <li class="sidebar-item">
                                    <a href="?app=assistenciatecnica/config-sms.php" class="sidebar-link"><span class="hide-menu"><span class="font-12">AT</span> | SMS</span></a>
                                </li>
                                <li class="sidebar-item">
                                    <a href="javascript:void(0)" class="sidebar-link link-travado"><span class="hide-menu"><span class="font-12">EO</span> | Status</span></a>
                                </li>
                                <li class="sidebar-item">
                                    <a href="javascript:void(0)" class="sidebar-link link-travado"><span class="hide-menu"><span class="font-12">EO</span> | Widget</span></a>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </li>
                <li class="list-divider"></li>
                <li class="sidebar-item">
                    <a class="sidebar-link has-arrow" href="javascript:void(0)" aria-expanded="false"><i class="far fa-id-card font-20 feather-icon"></i><span class="hide-menu">Portal do corretor</span></a>
                    <ul aria-expanded="false" class="collapse first-level base-level-line">
                        <li class="sidebar-item">
                            <a href="?app=portaldocorretor/corretores.php" class="sidebar-link"><span class="hide-menu"> Corretores</span></a>
                        </li>
                        <li class="sidebar-item">
                            <a class="has-arrow sidebar-link link-travado" href="javascript:void(0)" aria-expanded="false"><span class="hide-menu">Enviar arquivos</span></a>
                            <ul aria-expanded="false" class="collapse second-level base-level-line ml-0 pl-3">
                                <li class="sidebar-item">
                                    <a href="javascript:void(0)" class="sidebar-link link-travado"><span class="hide-menu"> Planilhas</span></a>
                                </li>
                                <li class="sidebar-item">
                                    <a href="javascript:void(0)" class="sidebar-link link-travado"><span class="hide-menu"> Documentos</span></a>
                                </li>
                                <li class="sidebar-item">
                                    <a href="javascript:void(0)" class="sidebar-link link-travado"><span class="hide-menu"> Imagens</span></a>
                                </li>
                            </ul>
                        </li>
                        <li class="sidebar-item">
                            <a class="has-arrow sidebar-link link-travado" href="javascript:void(0)" aria-expanded="false"><span class="hide-menu">Cursos e treinamentos</span></a>
                            <ul aria-expanded="false" class="collapse second-level base-level-line ml-0 pl-3">
                                <li class="sidebar-item">
                                    <a href="javascript:void(0)" class="sidebar-link link-travado"><span class="hide-menu"> Enviar vídeo</span></a>
                                </li>
                                <li class="sidebar-item">
                                    <a href="javascript:void(0)" class="sidebar-link link-travado"><span class="hide-menu"> Certificados</span></a>
                                </li>
                            </ul>
                        </li>
                        <li class="sidebar-item">
                            <a href="javascript:void(0)" class="sidebar-link link-travado"><span class="hide-menu"> Permissões de acesso</span></a>
                        </li>
                    </ul>
                </li>
                <li class="list-divider"></li>
                <li class="sidebar-item">
                    <a class="sidebar-link has-arrow" href="javascript:void(0)" aria-expanded="false"><i class="fas fa-chart-pie font-20 feather-icon"></i><span class="hide-menu">Relatórios</span></a>
                    <ul aria-expanded="false" class="collapse first-level base-level-line">
                        <li class="sidebar-item">
                            <a class="has-arrow sidebar-link" href="javascript:void(0)" aria-expanded="false"><span class="hide-menu">Assistência técnica</span></a>
                            <ul aria-expanded="false" class="collapse second-level base-level-line ml-0 pl-3">
                                <li class="sidebar-item">
                                    <a href="javascript:void(0)" class="sidebar-link"><span class="hide-menu"> item 1.3.1</span></a>
                                </li>
                                <li class="sidebar-item">
                                    <a href="javascript:void(0)" class="sidebar-link"><span class="hide-menu"> item 1.3.2</span></a>
                                </li>
                                <li class="sidebar-item">
                                    <a href="javascript:void(0)" class="sidebar-link"><span class="hide-menu"> item 1.3.3</span></a>
                                </li>
                            </ul>
                        </li>
                        <li class="sidebar-item">
                            <a class="has-arrow sidebar-link link-travado" href="javascript:void(0)" aria-expanded="false"><span class="hide-menu">Portal do cliente</span></a>
                            <ul aria-expanded="false" class="collapse second-level base-level-line ml-0 pl-3">
                                <li class="sidebar-item">
                                    <a href="javascript:void(0)" class="sidebar-link link-travado"><span class="hide-menu"> item 1.3.1</span></a>
                                </li>
                                <li class="sidebar-item">
                                    <a href="javascript:void(0)" class="sidebar-link link-travado"><span class="hide-menu"> item 1.3.2</span></a>
                                </li>
                                <li class="sidebar-item">
                                    <a href="javascript:void(0)" class="sidebar-link link-travado"><span class="hide-menu"> item 1.3.3</span></a>
                                </li>
                                <li class="sidebar-item">
                                    <a href="javascript:void(0)" class="sidebar-link link-travado"><span class="hide-menu"> Pesquisa de satisfação</span></a>
                                </li>
                            </ul>
                        </li>
                        <li class="sidebar-item">
                            <a class="has-arrow sidebar-link link-travado" href="javascript:void(0)" aria-expanded="false"><span class="hide-menu">Portal do corretor</span></a>
                            <ul aria-expanded="false" class="collapse second-level base-level-line ml-0 pl-3">
                                <li class="sidebar-item">
                                    <a href="javascript:void(0)" class="sidebar-link link-travado"><span class="hide-menu"> item 1.3.1</span></a>
                                </li>
                                <li class="sidebar-item">
                                    <a href="javascript:void(0)" class="sidebar-link link-travado"><span class="hide-menu"> item 1.3.2</span></a>
                                </li>
                                <li class="sidebar-item">
                                    <a href="javascript:void(0)" class="sidebar-link link-travado"><span class="hide-menu"> item 1.3.3</span></a>
                                </li>
                                <li class="sidebar-item">
                                    <a href="javascript:void(0)" class="sidebar-link link-travado"><span class="hide-menu"> Pesquisa de satisfação</span></a>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </li>
                <li class="list-divider"></li>
                <li class="sidebar-item">
                    <a class="sidebar-link has-arrow" href="javascript:void(0)" aria-expanded="false"><i class="fas fa-users font-20 feather-icon"></i><span class="hide-menu">Equipe</span></a>
                    <ul aria-expanded="false" class="collapse first-level base-level-line">
                        <li class="sidebar-item">
                            <a href="?app=usuarios/usuarios.php" class="sidebar-link"><span class="hide-menu"> Usuários</span></a>
                        </li>
                        <li class="sidebar-item">
                            <a class="has-arrow sidebar-link" href="javascript:void(0)" aria-expanded="false"><span class="hide-menu"> Configurações</span></a>
                            <ul aria-expanded="false" class="collapse second-level base-level-line ml-0 pl-3">
                                <li class="sidebar-item">
                                    <a href="?app=usuarios/config-funcoes.php" class="sidebar-link"><span class="hide-menu"> Funções</span></a>
                                </li>
                                <li class="sidebar-item">
                                    <a href="?app=usuarios/config-permissoes.php" class="sidebar-link"><span class="hide-menu"> Permissões de acesso</span></a>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </li>
                <li class="pt-4">
                    <img src="../../assets/images/ico-suporte.svg" class="img-fluid" title="Suporte">
                    <div style="width: max-content; margin: 0 auto; padding-top: 20px;">
                        <a class="" href="" style="border-radius: 11px; color: #ffffff; background-color: #212121; padding: 12px 40px; font-size: 14px; font-weight: 500;"><i class="fas fa-comment-dots font-20 align-middle pr-2"></i><span class="hide-menu">Suporte</span></a>
                    </div>
                    <br><br><br>
                </li>
                -->
            </ul>
        </nav>
    </div>
</aside>
</template>
<script>
import SideNav from "@/mixins/sideNav";

export default {
    mixins: [SideNav],
    methods:{
        logout(){
            this.$store.dispatch("logoutRequest").catch((err) => {});   
        },
    },
    mounted(){
        this.mountedMenu();
    }
}
</script>