import axios from 'axios';
import Cookies from 'js-cookie';
// Set config defaults when creating the instance
const instance = axios.create({
     baseURL: process.env.VUE_APP_API_HOST + '/api/'
   // baseURL: 'https://dev-back.qsuite.com.br/api/'
});

// Alter defaults after instance has been created
instance.defaults.headers.common['Authorization'] = Cookies.get('access_token')

export default instance;