<template>
<div class="page-breadcrumb">
    <div class="col-12">
        <div class="row">
            <div class="col-7 align-self-center pl-4">
                <h3 class="page-title text-truncate text-dark font-weight-medium mb-1">{{ title }}</h3>
                <div class="d-flex align-items-center">
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb m-0 p-0">
                            <li class="breadcrumb-item" v-for="item in pages" :key="item.name">
                                <b-link :to="{name: item.to}">{{ item.name }}</b-link>
                            </li>
                        </ol>
                    </nav>
                </div>
            </div>
            <div class="col-5 align-self-center text-right">
                <div class="ml-auto" v-if="menu.length > 0">
                    <div class="dropdown sub-dropdown">
                        <button class="btn btn-link text-muted dropdown-toggle" type="button" data-bs-toggle="dropdown">
                            <i class="icon-menu align-middle"></i> <span class="txt-acoes align-middle">MENU</span>
                        </button>
                        <div class="dropdown-menu ">
                            <b-link v-for="item in menu" :key="item.name" class="dropdown-item" :to="{name: item.to}">{{ item.name }}</b-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>
<script>
export default {
    props:{
        title:{
            type: String
        },
        menu:{
            type: Array,
            default: []
        },
        pages:{
            type: Array,
            default: []
        }
    }
}
</script>