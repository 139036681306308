import axios from 'axios';
import store from '@/store/index'

class RequestService {
  constructor() {
    this.interval = null;
  }

  startFetching() {
    if (this.interval) {
      return; // Já está rodando
    }

    this.fetchData();
    this.interval = setInterval(this.fetchData, 60000);
  }

  stopFetching() {
    if (this.interval) {
      clearInterval(this.interval);
      this.interval = null;
    }
  }

  isFetching() {
    return this.interval !== null;
  }

  fetchData() {
    const profile = store.getters.getProfile;
    if(profile){
      const data = {
        company_id: profile.company_id,
        user_id: profile.id,
      };
      const endpoint = process.env.VUE_APP_API_HOST + '/api/v2/is-online/store'
      axios.post(endpoint, data)
    }
  }
}

export default new RequestService();