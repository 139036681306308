import Cookies from 'js-cookie';

import actions from '@/store/modules/user/actions'
import getters from '@/store/modules/user/getters';
import mutations from '@/store/modules/user/mutations'


const state = {
    user: (Cookies.get('user')) ? JSON.parse(Cookies.get('user')) : {},
}


export {
    state,
    getters,
    actions,
    mutations,
}