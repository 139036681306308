<template>
    <div class="table-responsive">
        <table id="zero_config" class="table table-striped table-bordered no-wrap">
            <thead>
                <tr>
                    <th>Data de abertura</th>
                    <th>Status</th>
                    <th>Nome</th>
                    <th>CPF</th>
                    <th>Código</th>
                    <th>Empreendimento</th>
                    <th>N˚ APTO</th>
                    <th>Assunto</th>
                    <th>Técnico</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="item in data" :key="item.id">
                    <td>{{ item.created_at | date }}</td>
                    <td>{{ item.status == 'IN_PROGRESS' ? 'Em Andamento' : (item.status == 'PENDING' ? 'Novo' : 'Resolvido') }}</td>
                    <td>{{ item.client.first_name }} {{ item.client.last_name  }}</td>
                    <td>{{ item.client.cpf }}</td>
                    <td>{{ item.client_id }}</td>
                    <td>{{ item.enterprise.name }}</td>
                    <td>{{ item.floors.length ? item.floors[0].apt_number : 0 }}</td>
                    <td>{{ item.subject }}</td>
                    <td v-if="item.technical">{{ item.technical.first_name }} {{ item.technical.last_name }}</td>
                </tr>
            </tbody>
            <thead>
                <tr>
                    <th>Data de abertura</th>
                    <th>Status</th>
                    <th>Nome</th>
                    <th>CPF</th>
                    <th>Código</th>
                    <th>Empreendimento</th>
                    <th>N˚ APTO</th>
                    <th>Assunto</th>
                    <th>Técnico</th>
                </tr>
            </thead>
        </table>
    </div>
</template>
<script>
import moment from 'moment';

export default {
    props:{
        data:{
            type: Array,
            default: []
        }
    },
    filters:{
      date(date){
          return moment(date).format('DD/MM/YYYY H:MM');
      }
    },
}
</script>