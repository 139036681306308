<template>
    <vue-upload-multiple-image
        @upload-success="uploadImageSuccess"
        @before-remove="beforeRemove"
        @edit-image="editImage"
        :data-images="value"
        :editUpload="id"
        dragText="Clique ou arraste uma image aqui"
        browseText="Procurar imagem"
        :idUpload="id"
        :multiple="false"
        :maxImage="1"
        :showPrimary="false"
        :showEdit="false">
    </vue-upload-multiple-image>
</template>
<script>
import VueUploadMultipleImage from "vue-upload-multiple-image";

export default {
  components:{
       VueUploadMultipleImage
  },
  props:{
      value:{
          type: Array
      }
  },
  data(){
      return{
      }
  },
  computed:{
      id(){
          return String(parseInt(Math.random() * 100));
      }
  },
  methods:{
    uploadImageSuccess(formData, index, fileList) {
        this.$emit('input', fileList);
    },
    beforeRemove(index, done, fileList) {
        var r = confirm("Remover imagem?");
        if (r == true) {
            this.$emit('input', []);
            done();
        }
    },
    editImage(formData, index, fileList) {
        this.$emit('input', fileList);
    },
  }
}
</script>
