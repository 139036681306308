var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(!_vm.new_style)?_c('div',_vm._l((_vm.kits),function(item){return _c('a',{key:item.id,attrs:{"href":"javascript:void(0);"},on:{"click":function($event){return _vm.clicked(item)}}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.checkMainKit(item)),expression:"!checkMainKit(item)"}],staticClass:"d-kit-cta py-3 px-4 cursor-pointer",class:{ active_nav_kit: item.id == _vm.kit.id },style:({
          'background-color':
            item.id == _vm.kit.id ? 'var(--color) !important' : '',
          'border-color': item.id == _vm.kit.id ? 'var(--color) !important' : '',
        })},[_c('h5',{staticClass:"mb-0 title-dash-ar-inc",style:({
            'background-color':
              item.id == _vm.kit.id ? 'var(--color) !important' : '',
          })},[_vm._v(" "+_vm._s(item.name)+" ")])])])}),0):_c('div',_vm._l((_vm.kits),function(item){return _c('div',{key:item.id,staticClass:"item-menu"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.checkMainKit(item)),expression:"!checkMainKit(item)"}],staticClass:"d-kit-cta"},[_c('div',{staticClass:"flex-content"},[_c('img',{staticClass:"img-kit",attrs:{"src":item.image,"alt":item.name}}),_c('div',[_c('h5',{staticClass:"mb-0 title-dash-ar-inc"},[_vm._v(" "+_vm._s(item.name)+" ")]),_c('p',[_vm._v(_vm._s(item.description))])])]),_c('div',{staticClass:"btns"},[_c('h4',[_vm._v("R$ "+_vm._s(_vm.formatMoney(item.price)))]),_c('a',{on:{"click":function($event){$event.preventDefault();return _vm.addKartMenu(item)}}},[_c('button',{staticClass:"btn btn-info font-14 font-b-5",staticStyle:{"background-color":"#ffffff","color":"var(--color)"}},[_vm._v(" ADICIONAR ")])]),_c('a',{attrs:{"data-bs-toggle":"modal","data-bs-target":"#modal-add-products","href":"#modal-add-products"},on:{"click":function($event){$event.preventDefault();return _vm.clicked(item)}}},[_c('button',{staticClass:"btn btn-info font-14 font-b-5",staticStyle:{"background-color":"var(--color)"}},[_vm._v(" VER PRODUTOS ")])])])])])}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }