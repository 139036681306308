<template>
<div class="w-100 pl-lg-0 d-flex">
    <div class="card-body d-flex align-items-end" :style="{'background-image': `url(${required('bg-card-dashbord.jpg')}`}" style="background-size:cover; background-position:center center; border-radius: 22px; margin-bottom: 18px; min-height:280px">
        <div class="col-12 col-md-11 col-lg-6 p-0">
            <h4 class="text-white" style="font-size: 40px; line-height:45px; margin-top:30px"><b>envie sua<br>sugestões</b></h4>
            <p class="text-white">Conte o que poderia melhorar ou qual função você esta sentindo falta.</p>
        </div>
    </div>
</div>
</template>
<script>
export default {
    methods: {
        required(image){
            return require(`@/assets/img/${image}`); 
        },
    }
}
</script>