<template>
  <div
    id="app"
    :style="{
      '--color': component_color,
      '--color-technical': technical_component_color,
      '--color-broker': component_color_broker,
      '--color-broker-background': component_background_color_broker,
    }"
  >
    <!-- Admin -->
    <div v-if="isLogged" class="col-12 pl-0">
      <!-- Qsuite Admin -->
      <div
        id="main-wrapper"
        data-theme="light"
        data-layout="vertical"
        data-navbarbg="skin6"
        data-sidebartype="full"
        data-sidebar-position="fixed"
        data-header-position="fixed"
        data-boxed-layout="full"
        v-if="isQsuiteAdmin"
      >
        <BaseQsuiteNavbar />
        <b-row>
          <div
            :style="{ position: isMobile ? 'absolute' : 'relative' }"
            style="width: 290px"
          >
            <BaseQsuiteSideNav />
          </div>
          <div
            :class="!isMobile ? 'desktop-content' : 'mobile-content'"
            id="app"
          >
            <router-view></router-view>
          </div>
        </b-row>
      </div>

      <!-- Admin -->
      <div
        id="main-wrapper"
        data-theme="light"
        data-layout="vertical"
        data-navbarbg="skin6"
        data-sidebartype="full"
        data-sidebar-position="fixed"
        data-header-position="fixed"
        data-boxed-layout="full"
        v-if="isAdmin"
      >
        <BaseNavbar />
        <b-row>
          <div class="mainside"
            :style="{ position: isMobile ? 'absolute' : 'relative' }"
            style="width: 290px"
          >
            <BaseSideNav />
          </div>
          <div
            :class="!isMobile ? 'desktop-content' : 'mobile-content'"
            id="app"
          >
            <router-view></router-view>
          </div>
        </b-row>
      </div>

      <!-- começar -->
      <div v-if="isClient || isTechnical || isLiquidator || isBroker">
        <BaseNavbarClient
          v-if="isClient"
          :color="component_color"
          :logo="logo"
        />
        <BaseNavbarTechnical v-if="isTechnical" :logo="logo" />
        <BaseNavbarBroker
          v-if="isBroker && isLandingPage == false"
          :color="component_color"
          :logo="logo"
          :foto="broker.image"
          :logo_white="logo_white"
        />
        <b-row class="px-2">
          <router-view></router-view>
          <BaseTechnicalFooter v-if="isTechnical" :color="component_color" />
          <BaseClientFooter v-if="isClient" :color="component_color" />
        </b-row>
      </div>
    </div>

    <div v-else id="main-wrapper">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import store from "@/store/index";
import ThemeService from "@/services/resources/ThemeService";
import Cookies from "js-cookie";

const service = ThemeService.build();

export default {
  name: "app",
  data() {
    return {
      client: {
        width: 0,
      },
      logo_white: "",
      logo: "",
      component_color: "#fff",
      technical_component_color: "#fff",
      background_color: null,
      component_color_broker: "#fff",
      component_background_color_broker: "#fff",
    };
  },
  computed: {
    isLogged() {
      return store.getters.isAuthenticated;
    },
    isQsuiteAdmin() {
      return store.getters.isQsuiteAdmin;
    },
    isAdmin() {
      return store.getters.isAdmin;
    },
    isClient() {
      return store.getters.isClient || store.getters.isLiquidator;
    },
    isBroker() {
      return store.getters.isBroker;
    },
    broker(){
      return store.getters.getProfile.broker
    },
    isLandingPage() {
      if (this.$route.name == "LPCorretor") {
        return true
      } else {
        return false
      }
    
    },
    isTechnical() {
      return store.getters.isTechnical;
    },
    isLiquidator() {
      return store.getters.isLiquidator;
    },
    isMobile() {
      return this.client.width <= 768;
    },
  },
  methods: {
    handleResize() {
      // padrão
      this.client.width = window.innerWidth;
    },
    fetchTheme() {
      let data = {
        domain: window.location.hostname,
      };

      service.search(data).then((resp) => {
        if (resp[0] && resp[0].company_id) {
          Cookies.set("company_id", resp[0].company_id);
        } else {
          Cookies.remove("company_id");
        }

        resp.forEach((element) => {
          switch (element.key) {
            case "SETTINGS_LOGO":
              this.logo = element.value;
              break;
            case "SETTINGS_LOGO_WHITE":
              this.logo_white = element.value;
              break;
            case "PORTAL_CLIENT_COMPONENT_COLOR":
              this.component_color = element.value;
              break;
            case "PORTAL_BROKER_COMPONENT_COLOR":
              this.component_color_broker = element.value;
              break;
            case "PORTAL_BROKER_BACKGROUND_COLOR":
              this.component_background_color_broker = element.value;
              break;
            case "PORTAL_CLIENT_BACKGROUND_COLOR":
              this.background_color = element.value;
              break;
            case "PORTAL_TECHNICAL_COMPONENT_COLOR":
              this.technical_component_color = element.value;
              break;
            case "SETTINGS_ICO":
              $("#favicon").attr("href", element.value);
              break;
            case "SETTINGS_DISPLAY":
              document.title = element.value;
              break;
            case "SETTINGS_DOMAIN_BROKER":
              if (element.value === window.location.hostname) {
                Cookies.set("is_domain_broker", JSON.stringify(true));
              }
              break;

            case "SETTINGS_APTO_PERSONALIZATION_THEME":
              window.sessionStorage.KitTema = element.value;
              break;
          }
        });
      });
    },
  },
  mounted() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
    this.fetchTheme();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
};
</script>

<style lang="scss">
@import "./assets/scss/main.scss";
@import "./assets/themes.css";

* {
  font-family: "Montserrat", sans-serif;
}

body.preload {
  overflow: hidden;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
}

.desktop-content {
  width: calc(100% - 300px);
}

.mobile-content {
  width: 100%;
}

.card-item label {
  font-size: initial !important;
}

.card-item__name,
.card-item__number span {
  font-weight: 600 !important;
}

[data-anima="fade"] {
  animation: showFade 1s forwards;
}

@keyframes showFade {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

[data-anima="top"] {
  animation: showTop 0.5s forwards;
}

@keyframes showTop {
  from {
    opacity: 0;
    transform: translate3d(0, -20px, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

[data-anima="bottom"] {
  animation: showBottom 0.5s forwards;
}

@keyframes showBottom {
  from {
    opacity: 0;
    transform: translate3d(0, 20px, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

[data-anima="right"] {
  animation: showRight 0.5s forwards;
}

@keyframes showRight {
  from {
    opacity: 0;
    transform: translate3d(20px, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
[data-anima="left"] {
  animation: showLeft 0.5s forwards;
}

@keyframes showLeft {
  from {
    opacity: 0;
    transform: translate3d(-20px, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

p {
  font-size: 13px;
  font-weight: normal;
  color: var(--text-color);
  line-height: 1.5;
}

// loading

/* preload */
.carregando {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  background: var(--bg-checkout);
  z-index: 9999 !important;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.carregando.erro::before {
  content: "Ocorreu um erro ao processar a sua solicitação.";
  z-index: 9999;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 65px;
  padding: 0 20px;
  background: #ff0c37;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 13px;
  font-weight: 600;
  animation: showTop 0.5s forwards;
}

.carregando div {
  position: relative;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

// .loading {
//   width: 80px;
// }
// .loading-anima {
//   stroke-dasharray: 425.59;
//   stroke-dashoffset: 425.59;
//   animation: loading-animation 4s forwards infinite;
// }

// .loading-fixed {
//   position: absolute;
//   z-index: -1;
// }
.logo-loading {
  width: 170px;
  margin-top: -180px;
  animation: zoom 1.5s forwards infinite alternate;
}

.carregando.erro .logo-loading {
  animation: none;
}

@keyframes zoom {
  from {
    transform: scale(1);
  }

  to {
    transform: scale(1.1);
  }
}

.loading-carregando {
  position: relative;
  left: 0;
  width: 190px;
  border-radius: 10px;
  height: 3px;
  display: block;
  background: var(--border-color);
  margin-top: -30px;
  overflow: hidden;
}

.loading-carregando::before {
  content: "";
  width: 40%;
  height: 3px;
  border-radius: 10px;
  display: inline-block;
  position: absolute;
  left: 0;
  background: #003e53;
  animation: indeterminate 1.5s forwards infinite;
}

@keyframes indeterminate {
  0% {
    left: -35%;
    right: 100%;
  }

  100% {
    left: 100%;
    right: -90%;
  }
}

.carregando.erro .loading-carregando {
  display: none;
}

.mensagem-erro {
  padding: 40px;
  border: 1px solid #ff0c37;
  background: rgba(255, 12, 55, 0.1);
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  position: absolute;
  color: #ff0c37;
  font-size: 16px;
  font-weight: 600;
  margin-top: 20px;
  display: none;
}

.icon-footer {
  // width: 50px;
  // height: 50px;
}

.icon-footer:first-child {
  width: 90px;
  height: 30px;
}

.item-safe:first-child img {
  width: 25px;
  height: 34.09px;
}

.item-safe:nth-child(2) img {
  width: 28.46px;
  height: 34.12px;
}

.item-safe:nth-child(3) img {
  width: 29.14px;
  height: 31.16px;
}

// notificações
.toast {
  max-width: 400px !important;
  font-size: 14px !important;
  background-color: hsla(0, 0%, 100%, 0.85);
  background-clip: initial !important;
  border: 1px solid;
  box-shadow: none !important;
  border-radius: 5px !important;
  backdrop-filter: blur(0) !important;
  padding: 20px 30px;
  padding-left: 50px;
  font-family: Montserrat, sans-serif;
  outline: none !important;
  animation: showRight 0.3s forwards !important;
}

.b-toast,
.b-toaster-slot {
  max-width: 400px !important;
}

.b-toaster {
  z-index: 9999999 !important;
}

.toast-header,
.toast-body {
  padding: 0 !important;
  position: relative;
  line-height: 1.5 !important;
  font-size: 14px !important;
}

.toast-body {
  color: #5f5c6b !important;
}

.toast .close {
  font-weight: 500 !important;
  line-height: 1;
  color: #5f5c6b;
  text-shadow: none !important;
  opacity: 0.5;
  position: absolute !important;
  top: -10px !important;
  right: -10px !important;
  outline: none !important;
}

.toast-header strong {
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  font-weight: 600 !important;
}

.toast-header strong::before {
  content: "";
  background: url("./assets/img/icon/notify_default.svg") no-repeat center
    center;
  width: 20px;
  height: 20px;
  display: inline-block;
  position: absolute;
  left: -30px;
}

// danger
.b-toast-danger .toast-header strong {
  color: #ff0c37;
}

.b-toast-danger .toast-header strong::before {
  background: url("./assets/img/icon/notify_danger.svg") no-repeat center;
}

.b-toast-danger .toast {
  background-color: #fff3f5 !important;
  border-color: #ff0c37 !important;
  color: #ff0c37 !important;
}

.b-toast-danger .toast .toast-header {
  color: #ff0c37 !important;
  background-color: #fff3f5 !important;
  border: none !important;
}

.b-toast-danger.b-toast-solid .toast {
  background-color: #fff3f5 !important;
}

// info
.b-toast-info .toast-header strong {
  color: #3483fa;
}

.b-toast-info .toast-header strong::before {
  background: url("./assets/img/icon/notify_info.svg") no-repeat center;
}

.b-toast-info .toast {
  background-color: #f5f9ff !important;
  border-color: #3483fa !important;
  color: #3483fa !important;
}

.b-toast-info .toast .toast-header {
  color: #3483fa !important;
  background-color: #f5f9ff !important;
  border: none !important;
}

.b-toast-info.b-toast-solid .toast {
  background-color: #f5f9ff !important;
}

.form-lp {
  // background-color: red;
  padding-left: 25px !important;
}
.form-lp label {
  text-transform: initial;
  font-weight: 600;
  color: #656666;
  font-size: 14px;
  padding: 0;
  cursor: pointer;
}
.form-checkout label {
  text-transform: initial;
  font-weight: normal;
  color: #656666;
  font-size: 13px;
  padding: 0;
  cursor: pointer;
}
.form-lp.custom-checkbox
  .custom-control-input:checked
  ~ .custom-control-label::after {
  background-color: #d96113 !important;
}
.form-checkout.custom-checkbox
  .custom-control-input:checked
  ~ .custom-control-label::after {
  background-color: var(--color) !important;
}
@media screen and (max-width: 768px) {
  .VueCarousel-pagination {
    margin-top: -50px;
  }
}
</style>
