import Rest from '@/services/Rest';

/**
 * @typedef {UserLoggedService}
 */
export default class UserLoggedService extends Rest {
  /**
   * @type {String}
   */
  static resource = '/login/get-user'
}
