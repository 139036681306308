import Cookies from 'js-cookie';
import actions from '@/store/modules/auth/actions'
import getters from '@/store/modules/auth/getters';
import mutations from '@/store/modules/auth/mutations'

const state = {
  access_token: Cookies.get('access_token') || '',
  loading: false,
  isDomainBroker: Cookies.get('is_domain_broker') ? JSON.parse(Cookies.get('is_domain_broker')) : false
}

export {
  state,
  getters,
  mutations,
  actions
}