<template>
    <ul class="pagination float-right">
        <li class="page-item">
            <a class="page-link" href="javascript:void(0)"  @click="toPage(activePage - 1)">Anterior</a>
        </li>
        <li class="page-item" :class="{'active': n == activePage}" 
            v-for="n in pages"
            :key="n"
            @click="toPage(n)">
            <a class="page-link" href="javascript:void(0)">
                <span v-if="n > 0">{{ n }}</span>
                <span v-else>...</span>
            </a>
        </li>
        <li class="page-item">
            <a class="page-link" href="javascript:void(0)" @click="toPage(activePage + 1)">Próximo</a>
        </li>
    </ul>
</template>
<script>
export default {
props: {
    activePage: {
      type: Number,
      default: () => {
        return 1;
      },
    },
    totalPages: {
      type: Number,
      default: () => {
        return 1;
      },
    },
  },
  data: () => ({
    selectedPerPage: 10,
  }),
  computed: {
    pages: function () {
      let pages = Array.from({ length: this.totalPages }, (v, k) => k + 1);

      if (this.totalPages <= 12) {
        return pages;
      } else if (
        this.activePage <= 6 ||
        this.activePage >= this.totalPages - 5
      ) {
        pages.splice(6, this.totalPages - 12);

        pages.splice(6, 0, -1);

        return pages;
      } else if (this.activePage == 7) {
        pages.splice(7, this.totalPages - 12);
        pages.splice(7, 0, -1);

        return pages;
      } else if (this.activePage == this.totalPages - 6) {
        pages.splice(5, this.totalPages - 12);
        pages.splice(5, 0, -1);

        return pages;
      } else {
        pages.splice(5, this.totalPages - 10);
        pages.splice(5, 0, -1);
        pages.splice(5, 0, this.activePage);
        pages.splice(5, 0, -2);
        return pages;
      }
    },
  },
  methods: {
    perPage() {
      this.$emit("per-page", this.selectedPerPage);
    },
    toPage(page) {
      if (page < 0) {
        return;
      }
      if (page != this.activePage) {
        this.$emit("to-page", page);
      }
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
  },
}
</script>