$(function () {
    "use strict";

    // Feather Icon Init Js
    //feather.replace();

    $(".preloader").fadeOut();

    // this is for close icon when navigation open in mobile view
    $(".nav-toggler").on('click', function () {
        console.log('asdas');
        $("#main-wrapper").toggleClass("show-sidebar");
        $(".nav-toggler i").toggleClass("ti-menu");
    });

    // ==============================================================
    // Right sidebar options
    // ==============================================================
    $(function () {
        $(".service-panel-toggle").on('click', function () {
            $(".customizer").toggleClass('show-service-panel');

        });
        $('.page-wrapper').on('click', function () {
            $(".customizer").removeClass('show-service-panel');
        });
    });

    // ==============================================================
    //tooltip
    // ==============================================================
    $(function () {
        $('[data-toggle="tooltip"]').tooltip()
    })
    // ==============================================================
    //Popover
    // ==============================================================
    $(function () {
        $('[data-toggle="popover"]').popover()
    })

    // ==============================================================
    // Perfact scrollbar
    // ==============================================================
    $('.message-center, .customizer-body, .scrollable, .scroll-sidebar').perfectScrollbar({
       wheelPropagation: !0
    });


    // ==============================================================
    // Resize all elements
    // ==============================================================
    $("body, .page-wrapper").trigger("resize");
    $(".page-wrapper").delay(20).show();
    // ==============================================================
    // To do list
    // ==============================================================
    $(".list-task li label").click(function () {
        $(this).toggleClass("task-done");
    });

    // ==============================================================
    // This is for the innerleft sidebar
    // ==============================================================
    $(".show-left-part").on('click', function () {
        $('.left-part').toggleClass('show-panel');
        $('.show-left-part').toggleClass('ti-menu');
    });

    // For Custom File Input
    $('.custom-file-input').on('change', function () {
        //get the file name
        var fileName = $(this).val();
        //replace the "Choose a file" label
        $(this).next('.custom-file-label').html(fileName);
    })
});




/*CTA FAB
(function($) {
    "use strict";
  
    //scroll barra 
    $(window).on('scroll', function() {
        var wScroll = $(this).scrollTop();
        wScroll > 0 ? $('#fab').fadeIn() : $('#fab').fadeOut();
    });
  
  })(jQuery);
*/
  
  var overlay = $('#overlay');
  
  $('.cta-fab').hover(
      function() {
          $(this).addClass('expanded');
          overlay.show();
      },
      function() {
          $('.tab').removeClass('active');
          $(this).closest('.cta-fab').removeClass('expanded');
          overlay.hide();
      }
  );
  
  $('.cta-fab').on('click', function() {
      $(this).addClass('expanded');
      overlay.show();
      $('.tab').removeClass('active');
      $(this).find('.tab').first().addClass('active');
  });
  
  $('.tab').on('click', function(e) {
      e.stopPropagation();
      $('.tab').removeClass('active');
      $(this).addClass('active');
  });
  
  $('.close').on('click', function(e) {
      e.stopPropagation();
      e.preventDefault();
      $('.tab').removeClass('active');
      $(this).closest('.cta-fab').removeClass('expanded');
      overlay.hide();
  });