<template>
  <section>
    <div
      class="col-12 col-md-11 col-lg-11 mx-auto pt-4 order-10"
      v-if="!new_style"
    >
      <div
        class="px-3 py-2 text-white d-auto"
        style="
          background-color: #a7a7a7;
          border-radius: 8px;
          position: relative;
          bottom: -15px;
        "
      >
        <p class="page-txt-view-chamado"><b>Condições comerciais:</b></p>
      </div>
      <div
        class="px-4 py-3"
        style="border: 1px #ccc solid; border-radius: 12px"
      >
        <p class="font-11 pt-3 font-upper" style="text-transform: uppercase">
          {{ condition }}
        </p>
      </div>
    </div>
    <div v-if="new_style">
      <p class="font-12" style="color: #353535a6">
        <strong> Condições comerciais:</strong>
        {{ condition }}
      </p>
    </div>
  </section>
</template>
<script>
import ConditionService from "@/services/resources/ConditionService";
const service = ConditionService.build();

export default {
  props: {
    new_style: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      condition:
        "Imagens meramente ilustrativas. Inclusos: ITENS DECORATIVOS, PERSONALIZAÇÃO E ADMINISTRAÇÃO e MÃO DE OBRA E MONTAGEM.",
    };
  },
  methods: {
    fetchCondition() {
      service
        .search()
        .then((resp) => {
          if (Object.keys(resp).length > 0) {
            this.condition = resp.commercial;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  mounted() {
    this.fetchCondition();
  },
};
</script>