import Api from '@/services/API';
import Cookies from 'js-cookie';

/**
 * @typedef {AuthRequest}
 */

export default class Auth extends Api {

    /**
   * @type {String}
   */
    static base = '/oauth/token'


    /**
       * @type {String}
       */
    static resource = ''

    /**
       * @param {String} resource
       * @param {Object} options
       * @param {Object} http
       */
    constructor(resource, options = {}, http = null) {
        super(Auth.normalize(Auth.base, resource), options, http);
    }

    /**
   * @return {this}
   */
    static build() {
        return new this(this.resource);
    }

    login(data) {

        // let data = {
        //     username: username,
        //     password: password,
        //     grant_type: 'password',
        //     scope: '*',
        //     client_id: process.env.VUE_APP_OAUTH_CLIENT_id,
        //     client_secret: process.env.VUE_APP_OAUTH_CLIENT_SECRET
        // }
        return this.post('', data);
    }

    logout() {
        return this.post();
    }

}