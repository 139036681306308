import Rest from '@/services/Rest';

/**
 * @typedef {ThemeService}
 */
export default class ThemeService extends Rest {
  /**
   * @type {String}
   */
  static resource = '/theme'
}
