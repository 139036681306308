<template>
<footer class="footer text-center text-muted p-0">
    <div class="col-12 py-3">
        <div class="col-12">
            <div class="row">
                <div class="col-12 col-md-9 col-lg-10 text-center text-sm-left align-self-center mx-auto py-2">
                    © 2021 | QSuíte _Hub de integração de dados. Todos os direitos reservados. <a href="" target="_blank">Termos de uso</a> | <a href="" target="_blank">Política de privacidade.</a> | <a href="" target="_blank">Suporte</a>
                </div>
                <div class="col-12 col-md-3 col-lg-2 text-center text-sm-right align-self-center">
                    <router-link :to="{name: 'Index'}" v-if="logo"><img src="~@/assets/img/logo-gsuite.png" alt="Qsuite" style="max-height: 23px;"></router-link>
                </div>
            </div>
        </div>
    </div>
</footer>
</template>
<script>
export default {
    props:{
        logo:{
            type: String,
            default: null
        }
    }
}
</script>