import Cookies from 'js-cookie';
import AuthService from '@/services/resources/AuthService';
import LogoutService from '@/services/resources/LogoutService';
import LoginCodeService from "@/services/resources/LoginCodeService";
import router from '@/router/index'

export default {

    //fazer login do OAuth 2
    loginRequest(context, payload) {
        let service = AuthService.build();
        return new Promise((resolve, reject) => {

            let company_id = Cookies.get('company_id');

            if (company_id && window.location.pathname !== '/qs-admin') {
                payload['company_id'] = company_id;
            }

            service.login(payload)
                .then((resp) => {              
                    if(resp.access_token != null) {
                        let access_token = 'Bearer ' + resp.access_token;
                        Cookies.set('access_token', access_token, {
                            expires: 365
                        });

                        context.commit('authSuccess', access_token);

                        context.dispatch('userRequest');

                        resolve(access_token);
                    } else if(resp.message != null) {
                        context.commit('authError', resp.message);
                        Cookies.remove('access_token');
                        reject(resp.message)
                    }else{
                        console.log(resp);
                        context.commit('authError', resp);
                        Cookies.remove('access_token');
                        reject(resp)
                    }

                    
                })
                .catch((err) => {
                    context.commit('authError', err);
                    Cookies.remove('access_token');
                    reject(err);
                })
        })

    },

       //fazer login do OAuth 2
       loginFromCode(context, payload) {
        const service = LoginCodeService.build();
       
        return new Promise((resolve, reject) => {

            service.create(payload)
                .then((resp) => {
                  
                    let access_token = 'Bearer ' + resp.access_token;
                    Cookies.set('access_token', access_token, {
                        expires: 365
                    });

                    context.commit('authSuccess', access_token);

                    context.dispatch('userRequest');

                    resolve(access_token);
                })
                .catch((err) => {
                    context.commit('authError', err);
                    Cookies.remove('access_token');
                    reject(err);
                })
        })

    },

    loginFromToken(context, payload) {
        return new Promise((resolve, reject) => {
            let access_token = 'Bearer ' + payload;
            Cookies.set('access_token', access_token, {
                expires: 365
            });


            context.commit('authSuccess', access_token);
            context.dispatch('userRequest');
            resolve(access_token);
        });
    },

    logoutRequest: ({
        commit,
        dispatch
    }) => {
        const service = LogoutService.build();

        return new Promise((resolve, reject) => {
            service.get("")
                .then((resp) => {
                    commit('authLogout');
                    commit('userLogout');
                    resolve(resp);
                })
                .catch((err) => {
                    reject(err);
                });
        })

    },
    setLoading(context, payload) {
        return new Promise((resolve, reject) => {
            context.commit('setLoading', true);
        });
    },
    removeLoading(context, payload) {
        return new Promise((resolve, reject) => {
            context.commit('setLoading', false);
        });
    }
}