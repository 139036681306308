<template>
  <div
    class="w-100 p-dash-cliente-master-r"
    style="height: 130px; margin-top: 50px"
    :style="{ 'background-color': color }"
  >
    <footer
      class="footer text-center text-muted pl-0 p-dash-cliente-master-r"
      style="position: relative; top: -45px"
    >
      <div
        class="col-12 col-md-12 col-lg-11 px-0 py-3"
        style="background-color: #fff; border-radius: 0 15px 15px 0"
      >
        <div class="col-12">
          <div class="row">
            <div
              class="col-12 col-md-9 col-lg-9 align-self-center ml-auto py-2"
            >
              © {{ new Date().getFullYear() }} | QSuíte _Hub de integração de
              dados. Todos os direitos reservados.
              <a href="https://r4marketing.com.br/termos-de-uso" target="_blank"
                >Termos de uso</a
              >
              |
              <a
                href="https://www.cityinc.com.br/politica-de-privacidade"
                target="_blank"
                >Política de privacidade</a
              >
            </div>
            <div
              class="col-12 col-md-2 col-lg-3 text-center text-sm-right align-self-center"
            >
              <a href="https://qsuite.com.br" target="_blank"
                ><img
                  src="~@/assets/img/logo-gsuite.png"
                  alt="Qsuite"
                  style="max-height: 23px"
              /></a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: "#fff",
    },
  },
};
</script>