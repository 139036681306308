<template>
  <div>
    <div v-if="!new_style">
      <a
        href="javascript:void(0);"
        @click="clicked(item)"
        v-for="item in kits"
        :key="item.id"
      >
        <div
          class="d-kit-cta py-3 px-4 cursor-pointer"
          :class="{ active_nav_kit: item.id == kit.id }"
          :style="{
            'background-color':
              item.id == kit.id ? 'var(--color) !important' : '',
            'border-color': item.id == kit.id ? 'var(--color) !important' : '',
          }"
          v-show="!checkMainKit(item)"
        >
          <h5
            class="mb-0 title-dash-ar-inc"
            :style="{
              'background-color':
                item.id == kit.id ? 'var(--color) !important' : '',
            }"
          >
            {{ item.name }}
          </h5>
        </div>
      </a>
    </div>
    <div v-else>
      <div class="item-menu" v-for="item in kits" :key="item.id">
        <div class="d-kit-cta" v-show="!checkMainKit(item)">
          <div class="flex-content">
            <img :src="item.image" :alt="item.name" class="img-kit" />
            <div>
              <h5 class="mb-0 title-dash-ar-inc">
                {{ item.name }}
              </h5>
              <p>{{ item.description }}</p>
            </div>
          </div>
          <div class="btns">
            <h4>R$ {{ formatMoney(item.price) }}</h4>
            <a @click.prevent="addKartMenu(item)">
              <button
                class="btn btn-info font-14 font-b-5"
                style="background-color: #ffffff; color: var(--color)"
              >
                ADICIONAR
              </button>
            </a>

            <a
              @click.prevent="clicked(item)"
              data-bs-toggle="modal"
              data-bs-target="#modal-add-products"
              href="#modal-add-products"
            >
              <button
                class="btn btn-info font-14 font-b-5"
                style="background-color: var(--color)"
              >
                VER PRODUTOS
              </button>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Money from "@/mixins/money";
export default {
  mixins: [Money],

  props: {
    order: {
      type: Object,
      default: {},
    },
    kits: {
      type: Array,
    },
    kit: {
      type: Object,
    },
    new_style: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    clicked(item) {
      if (!this.checkMainKit(item)) {
        this.$emit("clicked", item.id);

        // setTimeout(() => {
        //   const access = document.getElementById("produtos-lista");
        //   access.scrollIntoView({ behavior: "smooth" }, true);
        // }, 300);
      }
    },
    addKartMenu(item) {
      if (!this.checkMainKit(item)) {
        this.$emit("addKartMenu", item.id);
      }
    },
    checkMainKit(item) {
      if (item.type == "SECONDARY") {
        if (typeof this.order.kits != "undefined") {
          for (let i = 0; i < this.order.kits.length; i++) {
            if (
              this.order.kits[i].type == "MAIN" &&
              (this.order.kits[i].free_secondary == null ||
                this.order.kits[i].free_secondary)
            ) {
              return false;
            }
          }
        }
      } else {
        return false;
      }

      return true;
    },
  },
};
</script>

<style scoped>
.item-menu .d-kit-cta {
  background: rgba(239, 239, 239, 0.45);
  padding: 30px;
  border-radius: 22px;
  display: grid;
  grid-template-columns: 2fr 1fr;
  align-items: center;
  border: none;
  box-shadow: none;
  gap: 30px;
}
.item-menu .flex-content {
  display: flex;
  align-items: center;
  gap: 20px;
}
.item-menu .img-kit {
  width: 140px;
  height: 140px;
  border-radius: 14px;
  object-fit: cover;
}
.d-kit-cta:hover {
  border-color: var(--color);
}
.item-menu .d-kit-cta + .item-menu .d-kit-cta {
  margin-top: 25px;
}
.item-menu .d-kit-cta h3,
.item-menu .d-kit-cta p {
  color: rgba(47, 47, 47, 0.7);
}
.item-menu .d-kit-cta p {
  margin-top: 10px;
}
.item-menu .btns {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.item-menu .btns a,
.item-menu .btns button {
  width: 100%;
  height: 60px;
  font-weight: 600;
  text-transform: uppercase;
}
.item-menu h4 {
  color: var(--color);
  font-weight: 600;
  font-size: 28px;
  margin-bottom: 10px;
}
@media screen and (max-width: 768px) {
  .item-menu .d-kit-cta {
    grid-template-columns: 1fr;
    gap: 10px;
  }
}
</style>