<template>
<div class="col-12 d-flex">
    <div class="text-center" style="position: absolute; z-index:2; bottom:0; right: 0;">
        <div class="w-100">
            <img src="~@/assets/img/pessoa-tudo.png" class="img-fluid mx-auto" style="" alt="Pessoa">
            <!--max-height:320px; max-width: 400px;-->
        </div>
    </div>
    <div class="col-12 col-md-12 col-lg-9 ml-auto d-flex p-0" :style="{'background-image': `url(${required('bg-card-dashbord.jpg')}`}" style=" background-size:cover; background-position:top center; border-radius: 22px; margin-bottom: 30px; min-height:560px">
        <div class="card-body p-4">
            <h4 class="text-white" style="font-size: 45px; line-height: 45px; margin-top: 25px; margin-left: 10px;"><b>tudo em<br>um só<br>lugar</b></h4>
        </div>
    </div>
</div>
</template>

<script>
export default {
    methods: {
        required(image){
            return require(`@/assets/img/${image}`); 
        },
    }
}
</script>