import middleware from "@/router/middleware";
import authentication from "@/router/middleware/authentication";
// import blockRouterByCompany from "@/router/middleware/blockRouterByCompany";
import unauthentication from "@/router/middleware/unauthentication";
import store from "../../store";
import instance from "../../config/service/axios";
import Cookies from "js-cookie";

export default [
  {
    path: "/",
    name: "Index",
    component: async () => {
      let domain = window.location.hostname.split("app.");

      if (domain.length > 1) {
        domain.shift();
      }

      domain = domain.join("");

      const response = await instance.get("/theme", {
        params: {
          domain: domain,
        },
      });

      if (response.data.length) {
        Cookies.set("company_id", response.data[0].company_id);
      }
      //domainMaster seria o domínio principal que irá logar no admin na rota raiz
      let domainBroker = "",
        domainMaster = "";

      response.data.forEach((element) => {
        switch (element.key) {
          case "SETTINGS_DOMAIN_BROKER":
            domainBroker = element.value;
            break;
          case "SETTINGS_DOMAIN_MASTER":
            domainMaster = element.value;
            break;
        }
      });

      let domainVerify = window.location.hostname.split("app.");

      if (domainVerify.length > 1) {
        domainVerify.shift();
      }

      domainVerify = domainVerify.join("");

      if (
        domainMaster.length &&
        window.location.hostname.includes("app.") &&
        domainVerify.toLowerCase().includes(domainMaster.toLowerCase())
      ) {
        return import("../../views/Admin/Index.vue");
      }

      if (domainBroker === window.location.hostname) {
        return import("../../views/Agent/Dashboard.vue");
      } else {
        return import("../../views/Client/Index.vue");
      }
    },
   // beforeEnter: middleware([unauthentication]),
  },
  {
    path: "/login-corretor",
    name: "LoginBroker",
    component: () => import("../../views/Agent/CodeConfirmation.vue"),
   //beforeEnter: middleware([unauthentication]),
  },
  // {
  //   path: '/admin',
  //   name: 'IndexAdmin',
  //   component: () => import('../../views/Admin/Index.vue'),
  //   beforeEnter: middleware([unauthentication]),
  // },
  {
    path: "/admin/registro",
    name: "Register",
    component: () => import("../../views/Admin/Register.vue"),
    beforeEnter: middleware([unauthentication]),
  },
  {
    path: "/logs-sistema",
    name: "LogsSystem",
    component: () => import("../../views/Admin/Log/List.vue"),
    beforeEnter: middleware([authentication]),
  },
  {
    path: "/corretor-relatorio",
    name: "AccessBrokers",
    component: () => import("../../views/Admin/Reports/AccessBrokers.vue"),
    beforeEnter: middleware([authentication]),
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: () => {
      if (store.getters.isAdmin) {
        return import("../../views/Admin/Dashboard.vue");
      } else if (store.getters.isClient || store.getters.isLiquidator) {
        return import("../../views/Client/Dashboard.vue");
      } else if (store.getters.isQsuiteAdmin) {
        return import("../../views/Qsuite/Dashboard.vue");
      } else if (store.getters.isTechnical) {
        return import("../../views/Technical/Dashboard.vue");
      } else if (domainBroker === window.location.hostname) {
        return import("../../views/Agent/Dashboard.vue");
      }
    },
    beforeEnter: middleware([authentication]),
  },
  {
    path: "/alterar-senha",
    name: "ChangePassword",
    component: () => import("../../views/Agent/ChangePassword.vue"),
   // beforeEnter: middleware([authentication]),
  },
  {
    path: "/termos-de-uso",
    name: "TermosdeUso",
    component: () => import("../../views/Agent/TermosdeUso.vue"),
  },
  {
    path: "/politica-de-privacidade",
    name: "PoliticadePrivacidade",
    component: () => import("../../views/Agent/PoliticadePrivacidade.vue"),
  },
  {
    path: "/documents/:id",
    name: "DocumentsBroker",
    component: () => import("../../views/Agent/MenuDocuments.vue"),
   // beforeEnter: middleware([authentication]),
  },
  {
    path: "/empreendimentos",
    name: "Enterprise",
    component: () => import("../../views/Admin/Enterprise/List.vue"),
    beforeEnter: middleware([authentication]),
  },
  {
    path: "/empreendimentos/novo",
    name: "EnterpriseNew",
    component: () => import("../../views/Admin/Enterprise/New.vue"),
    beforeEnter: middleware([authentication]),
  },
  {
    path: "/empreendimentos/editar/:id",
    name: "EnterpriseEdit",
    component: () => import("../../views/Admin/Enterprise/New.vue"),
    beforeEnter: middleware([authentication]),
  },
  {
    path: "/empreendimentos/configuracoes/tipo",
    name: "EnterpriseConfigType",
    component: () => import("../../views/Admin/Enterprise/ConfigType.vue"),
    beforeEnter: middleware([authentication]),
  },
  {
    path: "/empreendimentos/configuracoes/status",
    name: "EnterpriseConfigStatus",
    component: () => import("../../views/Admin/Enterprise/ConfigStatus.vue"),
    beforeEnter: middleware([authentication]),
  },
  {
    path: "/empreendimentos/configuracoes/diferenciais",
    name: "EnterpriseConfigDifferences",
    component: () =>
      import("../../views/Admin/Enterprise/ConfigDifferences.vue"),
    beforeEnter: middleware([authentication]),
  },
  {
    path: "/empreendimentos/configuracoes/condominio",
    name: "EnterpriseConfigCond",
    component: () => import("../../views/Admin/Enterprise/ConfigCond.vue"),
    beforeEnter: middleware([authentication]),
  },
  {
    path: "/empreendimentos/configuracoes/metragem",
    name: "EnterpriseConfigMetragem",
    component: () => import("../../views/Admin/Enterprise/ConfigMetragem.vue"),
    beforeEnter: middleware([authentication]),
  },
  {
    path: "/empreendimentos/configuracoes/finalidade",
    name: "EnterpriseConfigFinalidade",
    component: () =>
      import("../../views/Admin/Enterprise/ConfigFinalidade.vue"),
    beforeEnter: middleware([authentication]),
  },
  {
    path: "/empreendimentos/configuracoes/quarto",
    name: "EnterpriseConfigQuarto",
    component: () => import("../../views/Admin/Enterprise/ConfigQuarto.vue"),
    beforeEnter: middleware([authentication]),
  },
  {
    path: "/empreendimentos/configuracoes/suite",
    name: "EnterpriseConfigSuite",
    component: () => import("../../views/Admin/Enterprise/ConfigSuite.vue"),
    beforeEnter: middleware([authentication]),
  },
  {
    path: "/empreendimentos/configuracoes/tipologia",
    name: "EnterpriseConfigTipology",
    component: () =>
      import("../../views/Admin/Enterprise/ConfigTipology/List.vue"),
    beforeEnter: middleware([authentication]),
  },
  {
    path: "/empreendimentos/configuracoes/tipologia/novo",
    name: "EnterpriseConfigTipologyNew",
    component: () =>
      import("../../views/Admin/Enterprise/ConfigTipology/New.vue"),
    beforeEnter: middleware([authentication]),
  },
  {
    path: "/empreendimentos/configuracoes/tipologia/editar/:id",
    name: "EnterpriseConfigTipologyEdit",
    component: () =>
      import("../../views/Admin/Enterprise/ConfigTipology/New.vue"),
    beforeEnter: middleware([authentication]),
  },
  {
    path: "/empreendimentos/relacionar",
    name: "EnterpriseRelation",
    component: () => import("../../views/Admin/Enterprise/Relation.vue"),
    beforeEnter: middleware([authentication]),
  },
  {
    path: "/clientes",
    name: "Client",
    component: () => {
      if (store.getters.isAdmin) {
        return import("../../views/Admin/Client/List.vue");
      } else if (store.getters.isQsuiteAdmin) {
        return import("../../views/Qsuite/Client/List.vue");
      }
    },
    beforeEnter: middleware([authentication]),
  },
  {
    path: "/acesso",
    name: "ClientAccess",
    component: () => {
      if (store.getters.isAdmin) {
        return import("../../views/Admin/ClientAccess/List.vue");
      }
    },
    beforeEnter: middleware([authentication]),
  },
  {
    path: "/cria-acesso",
    name: "ClientAccessNew",
    component: () => {
      if (store.getters.isAdmin) {
        return import("../../views/Admin/ClientAccess/New.vue");
      }
    },
    beforeEnter: middleware([authentication]),
  },
  {
    path: "/clientes/novo",
    name: "ClientNew",
    component: () => {
      if (store.getters.isAdmin) {
        return import("../../views/Admin/Client/New.vue");
      } else if (store.getters.isQsuiteAdmin) {
        return import("../../views/Qsuite/Client/New.vue");
      }
    },
    beforeEnter: middleware([authentication]),
  },
  {
    path: "/clientes/editar/:id",
    name: "ClientEdit",
    component: () => {
      if (store.getters.isAdmin) {
        return import("../../views/Admin/Client/New.vue");
      } else if (store.getters.isQsuiteAdmin) {
        return import("../../views/Qsuite/Client/New.vue");
      }
    },
    beforeEnter: middleware([authentication]),
  },

  {
    path: "/imobiliarias",
    name: "RealState",
    component: () => import("../../views/Admin/RealState/List.vue"),
    beforeEnter: middleware([authentication]),
  },
  {
    path: "/imobiliaria/novo",
    name: "RealStateNew",
    component: () => import("../../views/Admin/RealState/New.vue"),
    beforeEnter: middleware([authentication]),
  },
  {
    path: "/imobiliaria/editar/:id",
    name: "RealStateEdit",
    component: () => import("../../views/Admin//RealState/New.vue"),
    beforeEnter: middleware([authentication]),
  },

  {
    path: "/corretores",
    name: "Broker",
    component: () => import("../../views/Admin/Broker/List.vue"),
    beforeEnter: middleware([authentication]),
  },
  {
    path: "/novo-cadastro",
    name: "BrokerCreateAccount",
    component: () => import("../../views/Agent/CreateAccount"),
    //beforeEnter: middleware([unauthentication]),
  },
  {
    path: "/corretores/novo",
    name: "BrokerNew",
    component: () => import("../../views/Admin/Broker/New.vue"),
    beforeEnter: middleware([authentication]),
  },
  {
    path: "/corretores/editar/:id",
    name: "BrokerEdit",
    component: () => import("../../views/Admin/Broker/New.vue"),
    beforeEnter: middleware([authentication]),
  },
  {
    path: "/corretor-perfil",
    name: "BrokerMe",
    component: () => import("../../views/Agent/Me.vue"),
   // beforeEnter: middleware([authentication]),
  },
  {
    path: "/empreendimento-arquivos",
    name: "EnterpriseFile",
    component: () =>
      import("../../views/Admin/EnterpriseFile/EnterpriseFile.vue"),
    beforeEnter: middleware([authentication]),
  },
  {
    path: "/lista-arquivos",
    name: "EnterpriseFileList",
    component: () =>
      import("../../views/Admin/EnterpriseFile/EnterpriseFileList.vue"),
    beforeEnter: middleware([authentication]),
  },
  {
    path: "/lista-arquivos/:id",
    name: "EnterpriseFileEdit",
    component: () =>
      import("../../views/Admin/EnterpriseFile/EnterpriseFileEdit.vue"),
    beforeEnter: middleware([authentication]),
  },
  {
    path: "/tecnicos",
    name: "Technical",
    component: () => import("../../views/Admin/Technical/List.vue"),
    beforeEnter: middleware([authentication]),
  },
  {
    path: "/tecnicos/novo",
    name: "TechnicalNew",
    component: () => import("../../views/Admin/Technical/New.vue"),
    beforeEnter: middleware([authentication]),
  },
  {
    path: "/tecnicos/editar/:id",
    name: "TechnicalEdit",
    component: () => import("../../views/Admin/Technical/New.vue"),
    beforeEnter: middleware([authentication]),
  },
  {
    path: "/sindicos",
    name: "Liquidator",
    component: () => import("../../views/Admin/Liquidators/List.vue"),
    beforeEnter: middleware([authentication]),
  },
  {
    path: "/sindicos/novo",
    name: "LiquidatorNew",
    component: () => import("../../views/Admin/Liquidators/New.vue"),
    beforeEnter: middleware([authentication]),
  },
  {
    path: "/sindicos/editar/:id",
    name: "LiquidatorEdit",
    component: () => import("../../views/Admin/Liquidators/New.vue"),
    beforeEnter: middleware([authentication]),
  },
  {
    path: "/chamados",
    name: "Report",
    component: () => {
      if (store.getters.isAdmin) {
        return import("../../views/Admin/Reports/List.vue");
      } else if (store.getters.isClient || store.getters.isLiquidator) {
        return import("../../views/Client/Reports/List.vue");
      } else {
        return import("../../views/Technical/Reports/List.vue");
      }
    },
    beforeEnter: middleware([authentication]),
  },
  {
    path: "/chamados/novo",
    name: "ReportNew",
    component: () => {
      if (store.getters.isAdmin) {
        return import("../../views/Admin/Reports/New.vue");
      } else if (store.getters.isClient || store.getters.isLiquidator) {
        return import("../../views/Client/Reports/New.vue");
      } else {
        return import("../../views/Technical/Reports/New.vue");
      }
    },
    beforeEnter: middleware([authentication]),
  },
  {
    path: "/chamados/editar/:id",
    name: "ReportEdit",
    component: () => {
      if (store.getters.isAdmin) {
        return import("../../views/Admin/Reports/New.vue");
      } else if (store.getters.isClient || store.getters.isLiquidator) {
        return import("../../views/Client/Reports/Edit.vue");
      } else {
        return import("../../views/Technical/Reports/Edit.vue");
      }
    },
    beforeEnter: middleware([authentication]),
  },
  {
    path: "/configuracoes/status",
    name: "ConfigStatus",
    component: () => import("../../views/Admin/Config/Status.vue"),
    beforeEnter: middleware([authentication]),
  },
  {
    path: "/configuracoes/email",
    name: "ConfigEmail",
    component: () => import("../../views/Admin/Config/Email.vue"),
    beforeEnter: middleware([authentication]),
  },
  {
    path: "/configuracoes/precedentes",
    name: "ConfigPrecedents",
    component: () => import("../../views/Admin/Config/Precedents.vue"),
    beforeEnter: middleware([authentication]),
  },
  {
    path: "/configuracoes/nao-conformidades",
    name: "ConfigNotOk",
    component: () => import("../../views/Admin/Config/NotOk.vue"),
    beforeEnter: middleware([authentication]),
  },
  {
    path: "/configuracoes/sms",
    name: "ConfigSms",
    component: () => import("../../views/Admin/Config/Sms.vue"),
    beforeEnter: middleware([authentication]),
  },
  {
    path: "/configuracoes/bem-vindo",
    name: "ConfigWelcome",
    component: () => import("../../views/Admin/Config/Welcome.vue"),
    beforeEnter: middleware([authentication]),
  },
  {
    path: "/configuracoes/categorias",
    name: "ConfigCategory",
    component: () => import("../../views/Admin/Config/Category.vue"),
    beforeEnter: middleware([authentication]),
  },
  {
    path: "/configuracoes/itens",
    name: "ConfigTypeItens",
    component: () =>
      import("../../views/Admin/Config/PersonalizationTypeItens/List.vue"),
    beforeEnter: middleware([authentication]),
  },
  {
    path: "/configuracoes/itens/novo",
    name: "ConfigTypeItemNew",
    component: () =>
      import("../../views/Admin/Config/PersonalizationTypeItens/New.vue"),
    beforeEnter: middleware([authentication]),
  },
  {
    path: "/configuracoes/itens/editar/:id",
    name: "ConfigTypeItemEdit",
    component: () =>
      import("../../views/Admin/Config/PersonalizationTypeItens/New.vue"),
    beforeEnter: middleware([authentication]),
  },
  {
    path: "/configuracoes/tipo",
    name: "ConfigType",
    component: () => import("../../views/Admin/Config/Type/List.vue"),
    beforeEnter: middleware([authentication]),
  },
  {
    path: "/configuracoes/tipo/novo",
    name: "ConfigTypeNew",
    component: () => import("../../views/Admin/Config/Type/New.vue"),
    beforeEnter: middleware([authentication]),
  },
  {
    path: "/configuracoes/type/editar/:id",
    name: "ConfigTypeEdit",
    component: () => import("../../views/Admin/Config/Type/New.vue"),
    beforeEnter: middleware([authentication]),
  },
  {
    path: "/configuracoes/nichos",
    name: "ConfigNiche",
    component: () => import("../../views/Admin/Config/Niche/List.vue"),
    beforeEnter: middleware([authentication]),
  },
  {
    path: "/configuracoes/nichos/novo",
    name: "ConfigNicheNew",
    component: () => import("../../views/Admin/Config/Niche/New.vue"),
    beforeEnter: middleware([authentication]),
  },
  {
    path: "/configuracoes/nichos/editar/:id",
    name: "ConfigNicheEdit",
    component: () => import("../../views/Admin/Config/Niche/New.vue"),
    beforeEnter: middleware([authentication]),
  },
  {
    path: "/configuracoes/galerias",
    name: "ConfigGallery",
    component: () => import("../../views/Admin/Config/Gallery/List.vue"),
    beforeEnter: middleware([authentication]),
  },
  {
    path: "/configuracoes/galerias/novo",
    name: "ConfigGalleryNew",
    component: () => import("../../views/Admin/Config/Gallery/New.vue"),
    beforeEnter: middleware([authentication]),
  },
  {
    path: "/configuracoes/galerias/editar/:id",
    name: "ConfigGalleryEdit",
    component: () => import("../../views/Admin/Config/Gallery/New.vue"),
    beforeEnter: middleware([authentication]),
  },
  {
    path: "/configuracoes/subdivisoes",
    name: "ConfigSubdivision",
    component: () => import("../../views/Admin/Config/Subdivision.vue"),
    beforeEnter: middleware([authentication]),
  },
  // {
  //   path: '/cliente',
  //   name: 'ClientList',
  //   component: () => import('../../views/Client/Index.vue'),
  //   beforeEnter: middleware([unauthentication]),
  // },
  {
    path: "/sindico",
    name: "ClientLogin",
    component: () => import("../../views/Client/Index.vue"),
    beforeEnter: middleware([unauthentication]),
  },
  {
    path: "/registro",
    name: "LiquidatorLogin",
    component: () => import("../../views/Client/Register.vue"),
    beforeEnter: middleware([unauthentication]),
  },
  {
    path: "/admin/recuperar-senha",
    name: "AdminRecovery",
    component: () => import("../../views/Recovery.vue"),
    beforeEnter: middleware([unauthentication]),
  },
  {
    path: "/corretor/recuperar-senha",
    name: "AgentRecovery",
    component: () => import("../../views/Recovery.vue"),
    beforeEnter: middleware([unauthentication]),
  },
  {
    path: "/cliente/recuperar-senha",
    name: "ClientRecovery",
    component: () => import("../../views/Recovery.vue"),
    beforeEnter: middleware([unauthentication]),
  },
  {
    path: "/sindico/recuperar-senha",
    name: "LiquidatorRecovery",
    component: () => import("../../views/Recovery.vue"),
    beforeEnter: middleware([unauthentication]),
  },
  {
    path: "/tecnico/recuperar-senha",
    name: "TechnicalRecovery",
    component: () => import("../../views/Recovery.vue"),
    beforeEnter: middleware([unauthentication]),
  },
  {
    path: "/tecnico",
    name: "TechnicalLogin",
    component: () => import("../../views/Technical/Index.vue"),
    beforeEnter: middleware([unauthentication]),
  },
  {
    path: "/pedidos",
    name: "Order",
    component: () => import("../../views/Admin/Order/List.vue"),
    beforeEnter: middleware([authentication]),
  },
  {
    path: "/pedido/:id",
    name: "InformationOrder",
    component: () => import("../../views/Admin/Order/Information.vue"),
    beforeEnter: middleware([authentication]),
  },  
  {
    path: "/pedido/old/:id",
    name: "InformationOrder",
    component: () => import("../../views/Admin/Order/InformationOld.vue"),
    beforeEnter: middleware([authentication]),
  },
  {
    path: "/gatilhos",
    name: "Trigger",
    component: () => import("../../views/Admin/Trigger/List.vue"),
    beforeEnter: middleware([authentication]),
  },
  {
    path: "/gatilhos/novo",
    name: "TriggerNew",
    component: () => import("../../views/Admin/Trigger/New.vue"),
    beforeEnter: middleware([authentication]),
  },
  {
    path: "/gatilhos/editar/:id",
    name: "TriggerEdit",
    component: () => import("../../views/Admin/Trigger/New.vue"),
    beforeEnter: middleware([authentication]),
  },
  {
    path: "/kit",
    name: "Kit",
    component: () => import("../../views/Admin/Kit/List.vue"),
    beforeEnter: middleware([authentication]),
  },
  {
    path: "/kit/novo",
    name: "KitNew",
    component: () => import("../../views/Admin/Kit/New.vue"),
    beforeEnter: middleware([authentication]),
  },
  {
    path: "/kit/editar/:id",
    name: "KitEdit",
    component: () => import("../../views/Admin/Kit/New.vue"),
    beforeEnter: middleware([authentication]),
  },
  {
    path: "/produtos",
    name: "Product",
    component: () => import("../../views/Admin/Product/List.vue"),
    beforeEnter: middleware([authentication]),
  },
  {
    path: "/produtos/novo",
    name: "ProductNew",
    component: () => import("../../views/Admin/Product/New.vue"),
    beforeEnter: middleware([authentication]),
  },
  {
    path: "/produtos/editar/:id",
    name: "ProductEdit",
    component: () => import("../../views/Admin/Product/New.vue"),
    beforeEnter: middleware([authentication]),
  },
  {
    path: "/perfil",
    name: "Profile",
    component: () => {
      if (store.getters.isAdmin) {
        return import("../../views/Admin/Reports/New.vue");
      } else if (store.getters.isClient || store.getters.isLiquidator) {
        return import("../../views/Client/Profile.vue");
      } else if (store.getters.isQsuiteAdmin) {
        return import("../../views/Qsuite/Profile.vue");
      } else {
        return import("../../views/Technical/Profile.vue");
      }
    },
    beforeEnter: middleware([authentication]),
  },
  {
    path: "/integracao",
    name: "Integration",
    component: () => import("../../views/Admin/Integration/List.vue"),
    beforeEnter: middleware([authentication]),
  },
  {
    path: "/integracao/novo",
    name: "integrationNew",
    component: () => {
      return import("../../views/Admin/Integration/New.vue");
    },
    beforeEnter: middleware([authentication]),
  },
  {
    path: "/integracao/editar/:id",
    name: "IntegrationEdit",
    component: () => {
      return import("../../views/Admin/Integration/New.vue");
    },
    beforeEnter: middleware([authentication]),
  },
  {
    path: "/usuarios",
    name: "User",
    component: () => import("../../views/Qsuite/User/List.vue"),
    beforeEnter: middleware([authentication]),
  },
  {
    path: "/usuarios/novo",
    name: "UserNew",
    component: () => import("../../views/Qsuite/User/New.vue"),
    beforeEnter: middleware([authentication]),
  },
  {
    path: "/usuarios/:id",
    name: "UserEdit",
    component: () => import("../../views/Qsuite/User/New.vue"),
    beforeEnter: middleware([authentication]),
  },
  {
    path: "/qs-admin",
    name: "Admin",
    component: () => import("../../views/Qsuite/Index.vue"),
    beforeEnter: middleware([unauthentication]),
  },
  {
    path: "/relatorios/cliente",
    name: "ReviewClient",
    component: () => import("../../views/Admin/Review/Client.vue"),
    beforeEnter: middleware([authentication]),
  },
  {
    path: "/relatorios/sindico",
    name: "ReviewLiquidator",
    component: () => import("../../views/Admin/Review/Liquidator.vue"),
    beforeEnter: middleware([authentication]),
  },
  {
    path: "/relatorios/tecnico",
    name: "ReviewTechnical",
    component: () => import("../../views/Admin/Review/Technical.vue"),
    beforeEnter: middleware([authentication]),
  },
  {
    path: "/relatorios/periodo",
    name: "ReviewDate",
    component: () => import("../../views/Admin/Review/Date.vue"),
    beforeEnter: middleware([authentication]),
  },

  {
    path: "/relatorios/status",
    name: "ReviewStatus",
    component: () => import("../../views/Admin/Review/Status.vue"),
    beforeEnter: middleware([authentication]),
  },
  {
    path: "/relatorios/empreendimento",
    name: "ReviewEnterprise",
    component: () => import("../../views/Admin/Review/Enterprise.vue"),
    beforeEnter: middleware([authentication]),
  },
  {
    path: "/relatorios/conformidade",
    name: "ReviewConformity",
    component: () => import("../../views/Admin/Review/Conformity.vue"),
    beforeEnter: middleware([authentication]),
  },
  {
    path: "/equipe/usuarios",
    name: "TimeUser",
    component: () => import("../../views/Admin/Time/User/List.vue"),
    beforeEnter: middleware([authentication]),
  },
  {
    path: "/equipe/usuarios/novo",
    name: "TimeUserNew",
    component: () => import("../../views/Admin/Time/User/New.vue"),
    beforeEnter: middleware([authentication]),
  },
  {
    path: "/equipe/usuarios/editar/:id",
    name: "TimeUserEdit",
    component: () => import("../../views/Admin/Time/User/New.vue"),
    beforeEnter: middleware([authentication]),
  },
  {
    path: "/equipe/funcoes",
    name: "TimeTitle",
    component: () => import("../../views/Admin/Time/Config/Title.vue"),
    beforeEnter: middleware([authentication]),
  },
  {
    path: "/equipe/permissoes",
    name: "TimePermission",
    component: () => import("../../views/Admin/Time/Config/Permission.vue"),
    beforeEnter: middleware([authentication]),
  },
  {
    path: "/configuracoes/tema",
    name: "SettingsTheme",
    component: () => import("../../views/Admin/Config/Theme.vue"),
    beforeEnter: middleware([authentication]),
  },
  {
    path: "/configuracoes/condicoes",
    name: "ConfigCondition",
    component: () => import("../../views/Admin/Config/Condition.vue"),
    beforeEnter: middleware([authentication]),
  },
  {
    path: "/configuracoes/condicoes-corretor",
    name: "ConfigAgentCondition",
    component: () => import("../../views/Admin/Config/AgentCondition.vue"),
    beforeEnter: middleware([authentication]),
  },
  {
    path: "/configuracoes/lpcorretor",
    name: "ConfigAgentLp",
    component: () => import("../../views/Admin/Config/AgentLp.vue"),
    beforeEnter: middleware([authentication]),
  },
  // Qsuite Admin
  {
    path: "/permissoes",
    name: "Permission",
    component: () => import("../../views/Qsuite/Permission/List.vue"),
    beforeEnter: middleware([authentication]),
  },
  {
    name: "SocialLogin",
    path: "/social-login",
    component: () => import("../../views/SocialLogin.vue"),
    beforeEnter: middleware([unauthentication]),
  },
  // Client

  {
    path: "/kit/arquivos-personalizacao",
    name: "FilesCustomization",
    component: () => import("../../views/Client/Kit/FilesCustomization.vue"),
    beforeEnter: middleware([authentication]),
  },
  {
    path: "/kit/minha-personalizacao",
    name: "MyCustomizations",
    component: () => import("../../views/Client/Kit/MyCustomizations.vue"),
    beforeEnter: middleware([authentication]),
  },
  {
    path: "/kit/minha-personalizacao/:id_custom",
    name: "MyCustomizationItem",
    component: () => import("../../views/Client/Kit/MyCustomizationItem.vue"),
    beforeEnter: middleware([authentication]),
  },
  {
    path: "/kit/bem-vindo",
    name: "KitWelcome",
    component: () => import("../../views/Client/Kit/Index.vue"),
    // beforeEnter: middleware([authentication, blockRouterByCompany]),
    beforeEnter: middleware([authentication]),
  },
  {
    path: "/kit/unidade",
    name: "KitUnity",
    component: () => import("../../views/Client/Kit/Unity.vue"),
    // beforeEnter: middleware([authentication, blockRouterByCompany]),
    beforeEnter: middleware([authentication]),
  },
  {
    path: "/kit/unidade/v2",
    name: "KitUnityV2",
    component: () => import("../../views/Client/Kit/Unity_v2.vue"),
    // beforeEnter: middleware([authentication, blockRouterByCompany]),
    beforeEnter: middleware([authentication]),
  },
  {
    path: "/kit/acabamentos",
    name: "KitProduct",
    props: true,
    component: () => import("../../views/Client/Kit/Products.vue"),
    beforeEnter: middleware([authentication]),
  },
  {
    path: "/kit/acabamentos/v2",
    name: "KitProductV2",
    props: true,
    component: () => import("../../views/Client/Kit/Products_v2.vue"),
    beforeEnter: middleware([authentication]),
  },
  {
    path: "/kit/shopping",
    name: "KitShop",
    component: () => import("../../views/Client/Kit/Shop.vue"),
    props: true,
    beforeEnter: middleware([authentication]),
  },
  {
    path: "/kit/shopping/v2",
    name: "KitShopV2",
    component: () => import("../../views/Client/Kit/Shop_v2.vue"),
    props: true,
    beforeEnter: middleware([authentication]),
  },
  {
    path: "/kit/checkout/v2",
    name: "CheckoutV2",
    component: () => import("../../views/Client/Kit/Checkout_v2.vue"),
    props: true,
    beforeEnter: middleware([authentication]),
  },
  {
    path: "/kit/obrigado",
    name: "KitThankYou",
    component: () => import("../../views/Client/Kit/ThankYou.vue"),
    beforeEnter: middleware([authentication]),
  },
  {
    path: "/kit/obrigado/v2",
    name: "KitThankYouV2",
    component: () => import("../../views/Client/Kit/ThankYou_v2.vue"),
    beforeEnter: middleware([authentication]),
  },
  {
    path: "/financeiro",
    name: "Financial",
    component: () => import("../../views/Client/Financial.vue"),
    beforeEnter: middleware([authentication]),
  },
  {
    path: "/leads",
    name: "LeadsList",
    component: () => import("../../views/Admin/Leads/List.vue"),
    beforeEnter: middleware([authentication]),
  },
  {
    path: "/suporte",
    name: "SuportList",
    component: () => import("../../views/Admin/Suport/List.vue"),
    beforeEnter: middleware([authentication]),
  },

  {
    path: "/suporte/novo",
    name: "SuportNew",
    component: () => import("../../views/Admin/Suport/New.vue"),
    beforeEnter: middleware([authentication]),
  },
  {
    path: "/suporte/editar/:id",
    name: "SuportNew",
    component: () => import("../../views/Admin/Suport/New.vue"),
    beforeEnter: middleware([authentication]),
  },
  {
    path: "/corretor/:id",
    name: "LPCorretor",
    component: () => import("../../views/Agent/LPCorretor"),
    // beforeEnter: middleware([unauthentication]),
  },
  {
    path: "/corretor/:id",
    name: "LPCorretor",
    component: () => import("../../views/Agent/LPCorretor"),
    // beforeEnter: middleware([unauthentication]),
  },

  {
    name: "pageNotFound",
    path: "*",
    component: () => import("../../views/PageNotFound.vue"),
  },
  {
    path: "/personalizacao/encerramento",
    name: "PersonalizationFinished",
    component: () => import("../../views/PersonalizationFinished.vue"),
  },
];
