import Rest from '@/services/Rest';

/**
 * @typedef {LoginCodeService}
 */
export default class LoginCodeService extends Rest {
  /**
   * @type {String}
   */
  static resource = '/code/login'
}
