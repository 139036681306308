<template>
    <div class="row">
        <div class="col-12">
            <label>Tipo de personalização</label>
            <div class="input-group">
                <div class="input-group-prepend">
                    <div class="input-group-text" id="btnGroupAddon2"><i class="fas fa-search"></i></div>
                </div>
                <input type="text" class="form-control" placeholder="Procurar" v-model="search">
            </div>
        </div>
        <div class="col-12">
            <div data-spy="scroll" data-offset="0" class="position-relative mt-2 scroll-1">
                <div class="col-12">
                    <b-form-checkbox-group
                        v-if="multiple"
                        v-model="selected"
                        :options="options">
                    </b-form-checkbox-group>

                    <b-form-radio-group
                        v-else
                        v-model="selected"
                        :options="options">
                    </b-form-radio-group>
                </div>
            </div>
       
        </div>
    </div>
</template>
<script>
import KitConfigService from "@/services/resources/KitConfigService";
const serviceKitConfig = KitConfigService.build();


export default {
    props:{
        value:{
            type: [Number, Array],
        },
        personalization_type_selected: {
            type: Number
        },
        multiple:{
            type: Boolean,
            default: false
        },
        personalization_type_id:{
            type: Number
        },   
    },
    data(){
        return{
            selected: [],
            search: '',
            optionsList: []
        }
    },
    computed:{
        options(){
            if (this.search == "") {
                return this.optionsList
            } else {
                return this.optionsList.filter(elem => {
                    if(elem.html.toLowerCase().indexOf(this.search.toLowerCase()) != -1) {
                        return elem.html
                    };
                });
            }
        }
    },
    watch:{ 
        value(newValue){
            this.selected = newValue;
        },
        selected(newValue){
            this.$emit('input', newValue);
        },
        personalization_type_selected(va) {
            console.log(va);
           this.selected = this.personalization_type_selected
        }
    },
    methods:{
       fetchType(){        
            let data = {    
                type: 'PERSONALIZATION_TYPE'
            };

            this.optionsList = [];
            serviceKitConfig
            .search(data)
                .then(resp => {
                  let vm = this;
                  let options = []
                //  setTimeout(function(){
                        resp.forEach(element => {
                            options[ options.length] = {
                                value: element.id,
                                html: element.name,
                                disabled: false
                            }
                        })
                        this.optionsList =  options
                        this.$emit('all_personalization_types', this.optionsList);
                  //  }, 1000)
                    console.log(this.optionsList);
                })
                .catch(err => {
                console.log(err)
            })
       },
    },
    mounted(){
        this.fetchType()
       
    }
}
</script>