<template>

  <div>
    <div class="" style="background-color: var(--color-broker-background); padding-top: 0px; padding-bottom: 0px; position: fixed; top: 0; width: 100%; z-index: 999;">
        <header class="topbar col-12 p-dash-cliente-master" data-navbarbg="skin6">
            <nav class="navbar top-navbar navbar-expand-md">
                <div class="navbar-header col p-0 order-1" data-logobg="skin6">
                    <div class="col mr-auto pr-0">
                        <b-link :to="{name: 'Dashboard'}" class=" ml-auto">
                            <img :src="logo_white" alt="Qsuite" style="max-height: 50px;">
                        </b-link>
                    </div>
                    <div class="col mr-auto pl-0 d-none">
                        <a class="topbartoggler d-block d-md-none waves-effect waves-light pl-0" href="javascript:void(0)" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <img src="@/assets/img/icons/loupe.png" alt="Qsuite" height="25">
                        </a>
                    </div>
                </div>
                <div class="navbar-collapse collapse px-0 order-2 order-md-1 order-lg-1" id="navbarSupportedContent">
                    <ul class="navbar-nav float-right ml-auto w-100 d-none">
                        <li class="nav-item w-100 py-sm-0 py-2">
                            <form>
                                <div class="customize-input px-3 w-100">
                                    <input class="form-control custom-radius border-0" type="search" placeholder="Busque por boleto, imóveis, comprovantes..." aria-label="Search" style="border: 1px #f6f6f6 solid !important; height: 44px; background-color: #f5f5f5;">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-search form-control-icon" style="top: 11px; right: 29px;"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>
                                </div>
                            </form>
                        </li>
                    </ul>
                </div>
                <div class="order-1 order-md-2 order-lg-2">
                    <ul class="navbar-nav float-right d-none">
                        <li class="nav-item">
                            <div class="customize-input">
                                <select class="custom-select form-control bg-white custom-radius custom-shadow border-0">
                                    <option selected="">add</option>
                                    <option value="1">imóvel</option>
                                    <option value="1">cliente</option>
                                    <option value="1">síndico</option>
                                    <option value="1">técnico</option>
                                </select>
                            </div>
                        </li>
                    </ul>
                    <ul class="navbar-nav float-right ml-auto">
                      
                        <li class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-settings svg-icon font-20"><circle cx="12" cy="12" r="3"></circle><path d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z"></path></svg>
                            </a>
                            <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdown" style="border-radius: 15px;">
                                <a class="dropdown-item d-none" href="?app=meus-imoveis.php"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-globe far fa-building mr-2 ml-1" style="color:#9eabc0; height: 17px; width: 17px;"><circle cx="12" cy="12" r="10"></circle><line x1="2" y1="12" x2="22" y2="12"></line><path d="M12 2a15.3 15.3 0 0 1 4 10 15.3 15.3 0 0 1-4 10 15.3 15.3 0 0 1-4-10 15.3 15.3 0 0 1 4-10z"></path></svg><span class="align-middle" style="font-size: 14px;"> Meus imóveis</span></a>
                                <div class="dropdown-divider d-none"></div>
                               
                                <b-link class="dropdown-item" :to="{name: 'ChangePassword'}"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-edit-3 feather-icon mr-2 ml-1" style="color:#9eabc0; height: 17px; width: 17px;"><path d="M12 20h9"></path><path d="M16.5 3.5a2.121 2.121 0 0 1 3 3L7 19l-4 1 1-4L16.5 3.5z"></path></svg><span class="align-middle" style="font-size: 14px;"> Alterar senha</span></b-link>
                                <!--
                                <div class="dropdown-divider"></div>
                                <a class="dropdown-item" href="#"><i data-feather="settings" class="feather-icon mr-2 ml-1" style="color:#9eabc0; height: 17px; width: 17px;"></i><span class="align-middle" style="font-size: 14px;"> Configurações da conta</a>
                                -->
                            </div> 
                        </li>
                        <li class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle" href="javascript:void(0)"  data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                               <b v-if="foto!=null"><img :src="foto" width="50" style="border-radius:131px"  /></b><b v-else> <img src="@/assets/img/icon-user.png" alt="user" class="rounded-circle" width="50" style="border: 2px var(--color) solid;"></b>
                                <span class="ml-2 d-none d-lg-inline-block d-none"><span style="font-size:11px; color:#fff;">MENU</span>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24"  height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-down svg-icon"><polyline points="6 9 12 15 18 9"></polyline></svg></span>
                            </a>
                            <div class="dropdown-menu dropdown-menu-right user-dd animated flipInY" style="border-radius: 15px;">
                                <b-link class="dropdown-item" to="/corretor-perfil"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-user svg-icon mr-2 ml-1" style="color:#9eabc0; height: 17px; width: 17px;"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg> <span class="align-middle" style="font-size: 14px;"> Perfil</span></b-link>
                                <div class="dropdown-divider"></div>
                                <!-- <a class="dropdown-item" href="javascript:void(0)"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-help-circle svg-icon mr-2 ml-1" style="color:#9eabc0; height: 17px; width: 17px;"><circle cx="12" cy="12" r="10"></circle><path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3"></path><line x1="12" y1="17" x2="12" y2="17"></line></svg> <span class="align-middle" style="font-size: 14px;"> Central de ajuda</span></a>
                                <div class="dropdown-divider"></div> -->
                                <a class="dropdown-item" @click="logout" href="#"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-power svg-icon mr-2 ml-1" style="color:#9eabc0; height: 17px; width: 17px;"><path d="M18.36 6.64a9 9 0 1 1-12.73 0"></path><line x1="12" y1="2" x2="12" y2="12"></line></svg> <span class="align-middle" style="font-size: 14px;"> Sair</span></a>
                            </div>
                        </li>
                    </ul>
                </div>
            </nav>
        </header>

    </div>
    <div style="margin-bottom: 80px;"></div>
</div>

</template>
    
    <script>
    export default {
        props:{
            logo:{
                type: String
            },
            foto: {
                type: String
            },
            color:{
                type: String
            },
            logo_white:{
                type: String
            }
        },
        data(){
            return{
                loading: true,
                background_color: null
            }
        },
        methods:{
          logout(){
            this.$store.dispatch("logoutRequest").catch((err) => {});   
          },
        },
        computed:{
            user(){
                return this.$store.getters.getProfile;
            }
        },
        mounted(){
            setTimeout(() => {
                this.loading = false;         
            }, 1000*3);
            console.log(this.logoPanel);
        }
    }
    </script>